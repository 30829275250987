// CalendarComponent.js
import { useEffect } from "react";
import { useDispatch } from "react-redux";
// import { date_change } from "../actions/dateActions";
import Calendar from "color-calendar";
import "color-calendar/dist/css/theme-glass.css";
import { date_change } from "../actions/crud";

const CalendarComponent = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const handleDateChange = (currentDate) => {
            let date = new Date(currentDate);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();

            month = month < 10 ? "0" + month : month;
            day = day < 10 ? "0" + day : day;

            let formattedDate = `${year}-${month}-${day}`;

            // Dispatch the action when the date changes
            dispatch(date_change(formattedDate));
        };

        new Calendar({
            id: "#myCal",
            theme: "glass",
            weekdayType: "long-upper",
            monthDisplayType: "long",
            calendarSize: "small",
            layoutModifiers: ["month-left-align"],
            eventsData: [
                {
                    id: 1,
                    name: "French class",
                    start: "2020-08-17T06:00:00",
                    end: "2020-08-18T20:30:00",
                },
                {
                    id: 2,
                    name: "Blockchain 101",
                    start: "2020-08-20T10:00:00",
                    end: "2020-08-20T11:30:00",
                },
            ],
            dateChanged: handleDateChange,
        });
    }, [dispatch]);

    return <div id="myCal"></div>;
};

export default CalendarComponent;
