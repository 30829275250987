import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signup } from "../actions/auth";
import Telmic_Logo from "../assets/telmic_logo.png";
import Modal from "../components/Modal";
import { useTitle } from "../hooks/useTitle";
import useWindowSize from "../hooks/useWindowSize";
import { loginPage_Laptop, loginPage_Mobile } from "../tailwindClasses/Styles";

import { useLanguage } from "../hooks/useLanguage";

const RegisterPage = () => {
    const dispatch = useDispatch();
    let [id, setId] = useState(0);
    const [message, setMessage] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false); // State to control modal visibility

    const language = useLanguage();
    useTitle(language.page_titles.register_page);

    let loginPageStyle;
    const { windowSize } = useWindowSize();

    if (windowSize.isMobile) {
        loginPageStyle = loginPage_Mobile;
    } else {
        loginPageStyle = loginPage_Laptop;
    }

    const {
        div1,
        div2,
        section1,
        div3,
        div4,
        img1,
        div5,
        div6,
        h1,
        label,
        input,
        button,
        p,
        last_div,
    } = loginPageStyle;

    const errors = useSelector((state) => state.auth.errors);
    const success = useSelector((state) => state?.auth?.success);

    const setTimedMessage = (msg, time = 2000) => {
        setMessage(msg);
        setTimeout(() => {
            setMessage("");
        }, time);
    };

    useEffect(() => {
        if (errors) {
            setMessage(errors);

            return () => setMessage("");
        }
    }, [errors]);

    useEffect(() => {
        if (success) {
            setShowModal(true);
        }
    }, [success]);

    //

    const handleRegister = async (e) => {
        e.preventDefault();

        if (!id || !password) {
            setTimedMessage("all_fields_required");
            return;
        }

        if (isNaN(id)) {
            setTimedMessage("id_mustbe_number");
            return;
        }

        id = Number(id); // Convert the username to a number
        try {
            dispatch(signup(id, password, language));
        } catch (error) {
            console.error("Registration failed", error);
        }
    };

    return (
        <div className={div1}>
            <div className={div2}>
                <section className={section1}>
                    <div className={div3}>
                        <div className={div4}>
                            <img
                                className={img1}
                                src={Telmic_Logo}
                                alt="logo"
                            />
                        </div>
                        <div className={div5}>
                            <div className={div6}>
                                <h1 className={h1}>
                                    {
                                        language.login_register_page
                                            .register_header
                                    }
                                </h1>
                                <div>
                                    <label htmlFor="email" className={label}>
                                        {language.login_register_page.easyproid}
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        className={input}
                                        // placeholder="name@company.com"
                                        required=""
                                        onChange={(e) => setId(e.target.value)}
                                    />
                                </div>
                                <div className="relative">
                                    <label htmlFor="password" className={label}>
                                        {language.login_register_page.password}
                                    </label>
                                    <input
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        name="password"
                                        id="password"
                                        placeholder="••••••••"
                                        className={input}
                                        required
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                    <button
                                        type="button"
                                        className="absolute inset-y-0 right-0 mr-3 mt-8"
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                    >
                                        {showPassword ? (
                                            <VisibilityOffIcon />
                                        ) : (
                                            <VisibilityIcon />
                                        )}
                                    </button>
                                </div>

                                <button
                                    className={button}
                                    onClick={handleRegister}
                                >
                                    {language.login_register_page.signup}
                                </button>
                                <p className={"text-center text-red-600"}>
                                    {message}
                                </p>

                                <p className={p}>
                                    {language.do_you_already_have_an_account}
                                </p>
                                <div
                                    className={last_div}
                                    onClick={() => navigate("/")}
                                >
                                    {language.login_register_page.signin}
                                </div>
                            </div>
                        </div>
                    </div>
                    {showModal && (
                        <Modal
                            showModal={showModal}
                            setShowModal={setShowModal}
                            toNavigate={true}
                            message={language.modal.registration_successful}
                        />
                    )}
                </section>
            </div>
        </div>
    );
};

export default RegisterPage;
