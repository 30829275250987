import * as actionType from "../constants/actionTypes";

const initialState = {
    authData: null,
    success: false,
    isLoading: true,
    errors: "",
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.START_LOADING:
            return { ...state, isLoading: true };
        case actionType.END_LOADING:
            return { ...state, isLoading: false };
        case actionType.AUTH:
            const token = action?.data?.token;
            if (token) {
                localStorage.setItem("token", JSON.stringify(token));
            }
            return {
                ...state,
                authData: action.data,
                isLoading: false,
                errors: null,
                success: action.success,
            };
        case actionType.LOGOUT:
            localStorage.clear();
            return { ...state, authData: null, isLoading: false, errors: null };
        case actionType.UPDATE_WORK_LOCATION:
            return {
                ...state,
                authData: {
                    ...state.authData,
                    work_location: action.data,
                },
                isLoading: false,
            };

        case actionType.AUTH_FAIL:
            return {
                ...state,
                errors: action.payload,
                isLoading: false,
            };
        case actionType.TOKEN_FAIL:
            alert(action.payload);
            localStorage.clear();
            return { ...state, authData: null, isLoading: false, errors: null };

        case actionType.RESET_SUCCESS:
            return {
                ...state,
                success: false,
            };

        default:
            return state;
    }
};

export default authReducer;
