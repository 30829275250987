import { useEffect, useState } from "react";
import Loading from "../../components/LoadingPage.js";
import { useLanguage } from "../../hooks/useLanguage.js";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    fetch_permission_data,
    fetch_single_user_foods,
    insert_food,
} from "../../actions/crud.js";
import {
    ChiryuFoodNames,
    HakataFoodNames,
    HonshaFoodNames,
    NagoyaFoodNames,
    ShimaneFoodNames,
    TokonameFoodNames,
    TokyoFoodNames,
} from "../../data/data";
import { useTitle } from "../../hooks/useTitle.js";
import useWindowSize from "../../hooks/useWindowSize.js";
import {
    FoodSelection_Laptop,
    FoodSelection_Mobile,
} from "../../tailwindClasses/Styles.js";

import Modal from "../../components/Modal.js";
import { getMonthOffset } from "../../utils/monthOffsetUtils.js";
// import ImageModal from "../../components/ImageModal.js";

const Foodselectionpage = () => {
    const dispatch = useDispatch();
    const floors = [1, 2];
    const [monthValue, setMonthValue] = useState("this_month");
    const [monthOffset, setMonthOffset] = useState(
        getMonthOffset("this_month"),
    );

    const [currentTime, setCurrentTime] = useState(new Date());
    const [isBefore8_30, setIsBefore8_30] = useState(false);
    const [isBefore1145AM, setIsBefore1145AM] = useState(false);

    // const [isImageOpen, setIsImageOpen] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            // Create a new Date object with timezone offset for Japan Standard Time (JST)
            const jstTime = new Date(
                new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" }),
            );
            setCurrentTime(jstTime);
        }, 60000); // Check every minute

        return () => clearInterval(interval); // Clean up interval on unmount
    }, []); // Empty dependency array to run effect only once

    useEffect(() => {
        const currentHour = currentTime.getHours();
        const currentMinute = currentTime.getMinutes();

        // Check if current time is before 8:30 AM
        setIsBefore8_30(
            currentHour < 8 || (currentHour === 8 && currentMinute < 45),
        );

        // Check if current time is before 11:45 AM
        setIsBefore1145AM(
            currentHour < 10 || (currentHour === 10 && currentMinute < 55),
        );
    }, [currentTime]); // Re-run effect when currentTime changes

    const navigate = useNavigate();
    const language = useLanguage();

    const easyproId = useSelector((state) => state?.auth?.authData?.easyproId);
    const work_location = useSelector(
        (state) => state?.auth?.authData?.work_location,
    );

    let FoodNames;
    switch (work_location) {
        case "常滑":
            FoodNames = TokonameFoodNames;
            break;
        case "本社":
            FoodNames = HonshaFoodNames;
            break;
        case "知立":
            FoodNames = ChiryuFoodNames;
            break;
        case "島根":
            FoodNames = ShimaneFoodNames;
            break;
        case "名古屋":
            FoodNames = NagoyaFoodNames;
            break;
        case "東京":
            FoodNames = TokyoFoodNames;
            break;
        case "博多":
            FoodNames = HakataFoodNames;
            break;
        default:
            FoodNames = TokonameFoodNames;
    }

    useEffect(() => {
        dispatch(
            fetch_single_user_foods(easyproId, language, monthOffset, navigate),
        );
        dispatch(fetch_permission_data(monthOffset));
    }, [dispatch, easyproId, monthOffset, language, navigate]);

    const { userFood } = useSelector((state) => state.crud);

    useEffect(() => {
        if (userFood) {
            setTempUserFood(userFood);
        }
    }, [userFood]);

    useTitle(language.page_titles.food_selection_page);

    const permissionData = useSelector(
        (state) => state.crud.data.permissionData,
    );

    let Style = {};
    const { windowSize } = useWindowSize();

    const [tempUserFood, setTempUserFood] = useState(userFood);
    const [modifiedUserFood, setModifiedUserFood] = useState([]);
    const [submissionData, setSubmissionData] = useState([]);
    const [showModal, setShowModal] = useState(false); // State to control modal visibility

    if (!userFood || !permissionData) {
        return <Loading />;
    }

    if (windowSize.isMobile) {
        Style = FoodSelection_Mobile;
    } else {
        Style = FoodSelection_Laptop;
    }
    const {
        div1,
        div2,
        special_div,
        middlediv,
        div3,
        div4,
        div5,
        div6,
        div6Disabled,
        div7,
        div8,
        div9,
        div10,
        select1,
        select2,
        button1,
        divMiddle,
        yellow_text,
        link_text,
    } = Style;

    const months = ["previous_month", "this_month", "next_month"];

    const handleFoodChange = (
        easyproId,
        date,
        selectedFood,
        selectedFloor = 1,
    ) => {
        const updatedTempUserFood = tempUserFood.map((item) => {
            if (item.date === date) {
                const modifiedItem = {
                    ...item,
                    name: selectedFood,
                    floor: selectedFloor,
                    checkedIn: item.checkedIn,
                };

                // Check if the item is already in modifiedUserFood
                const existingIndex = modifiedUserFood.findIndex(
                    (mItem) => mItem._id === item._id,
                );

                if (existingIndex === -1) {
                    // Item not in modifiedUserFood, add it
                    setModifiedUserFood((prev) => [...prev, modifiedItem]);
                } else {
                    // Item already in modifiedUserFood, update it
                    const updatedModifiedUserFood = [...modifiedUserFood];
                    updatedModifiedUserFood[existingIndex] = modifiedItem;
                    setModifiedUserFood(updatedModifiedUserFood);
                }

                // Check if the item is already in submissionData
                const existingSubmissionIndex = submissionData.findIndex(
                    (sItem) => sItem._id === item._id,
                );

                if (existingSubmissionIndex === -1) {
                    // Item not in submissionData, add it
                    setSubmissionData((prev) => [...prev, modifiedItem]);
                } else {
                    // Item already in submissionData, update it
                    const updatedSubmissionData = [...submissionData];
                    updatedSubmissionData[existingSubmissionIndex] =
                        modifiedItem;
                    setSubmissionData(updatedSubmissionData);
                }

                return modifiedItem;
            }
            return item;
        });

        setTempUserFood(updatedTempUserFood);
    };
    const checkboxClicked = (easyproId, date) => {
        const updatedTempUserFood = tempUserFood.map((item) => {
            if (item.date === date) {
                const modifiedItem = {
                    ...item,
                    checkedIn: !item.checkedIn,
                };

                const updatedSubmissionData = submissionData.map((sItem) =>
                    sItem._id === item._id ? modifiedItem : sItem,
                );

                if (
                    !updatedSubmissionData.find(
                        (sItem) => sItem._id === item._id,
                    )
                ) {
                    updatedSubmissionData.push(modifiedItem);
                }

                setSubmissionData(updatedSubmissionData);

                return modifiedItem;
            }
            return item;
        });
        setTempUserFood(updatedTempUserFood);
    };

    const handleSubmission = () => {
        setShowModal(true); // Show modal when submission button is clicked
        dispatch(insert_food(submissionData, monthOffset));
    };

    const handleSelection = (e) => {
        const selectedMonth = e.target.value;
        setMonthValue(selectedMonth);
        setMonthOffset(getMonthOffset(selectedMonth));
    };

    return (
        <div className={div1}>
            <div className={div2}>
                <div className={special_div}>
                    <select
                        value={monthValue} // Step 2: Set initial value to monthValue
                        onChange={handleSelection}
                        className="mx-auto my-auto ml-2 h-10 appearance-none rounded-full border border-gray-300 bg-white px-5 text-center text-gray-600 hover:border-gray-400 focus:outline-none"
                    >
                        {months.map((month) => (
                            <option key={month} value={month}>
                                {language.months[month]}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={div3}>
                    <div className="flex flex-col text-center">
                        <p>
                            {language.food_selection_page.food_selection_page}
                        </p>
                        <p className={yellow_text}>
                            {language.food_selection_page.deadline}
                        </p>
                    </div>
                </div>
                <div className={middlediv}>
                    <div>△=ごはん無し</div>
                    <div>○=ごはん普通</div>
                    <div>◎=ごはん大盛り</div>
                </div>
            </div>
            {/* <div className="flex">
        <button className={link_text} onClick={() => setIsImageOpen(true)}>
          <p className={link_text}>{language.food_selection_page.link_text}</p>
        </button>
        <ImageModal isImageOpen={isImageOpen} setIsImageOpen={setIsImageOpen} />
      </div> */}
            <div className={div4}>
                <div className={div5}>
                    {tempUserFood &&
                        tempUserFood.map((item) => {
                            const { checkedIn, date, name, floor, dayOfWeek } =
                                item;

                            let filteredFoodNames = FoodNames;

                            if (dayOfWeek === 6) {
                                filteredFoodNames = FoodNames.filter(
                                    (food) => !food.includes("Cランチ"),
                                );
                            } else {
                                filteredFoodNames = FoodNames.filter(
                                    (food) => !food.includes("丼セット"),
                                );
                            }

                            // Check if the date is today or past
                            const currentDate = new Date()
                                .toLocaleString("ja-JP", {
                                    timeZone: "Asia/Tokyo",
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                })
                                .split("T")[0]
                                .replace(/\//g, "-");
                            const permissionDate = new Date(date)
                                .toISOString()
                                .split("T")[0];

                            const isPast = permissionDate < currentDate;
                            const isToday = permissionDate === currentDate;

                            // Check if allow is false based on permissionData
                            const permissionAllow = permissionData.find(
                                (permission) => permission.date === date,
                            )?.allow;

                            let fullyDenied = false;

                            fullyDenied =
                                isPast ||
                                !permissionAllow ||
                                (!isBefore8_30 && isToday);

                            return (
                                <div
                                    key={date}
                                    className={
                                        isPast || !permissionAllow
                                            ? div6Disabled
                                            : div6
                                    }
                                >
                                    <div className={div7}>
                                        {date}
                                        <div>{language.day[dayOfWeek]}</div>
                                    </div>

                                    <div className={div8}>
                                        <select
                                            className={select1}
                                            value={name || "Select Food"}
                                            onChange={(e) =>
                                                handleFoodChange(
                                                    easyproId,
                                                    date,
                                                    e.target.value ===
                                                        "Select Food"
                                                        ? ""
                                                        : e.target.value,
                                                    floor,
                                                )
                                            }
                                            disabled={fullyDenied}
                                        >
                                            {name &&
                                            !filteredFoodNames.includes(
                                                name,
                                            ) ? (
                                                <>
                                                    <option
                                                        key={name}
                                                        value={name}
                                                    >
                                                        {name}
                                                    </option>
                                                    {filteredFoodNames.map(
                                                        (food, index) => (
                                                            <option
                                                                key={index}
                                                                value={food}
                                                            >
                                                                {food}
                                                            </option>
                                                        ),
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <option value="Select Food">
                                                        Select Food
                                                    </option>
                                                    {filteredFoodNames.map(
                                                        (food, index) => (
                                                            <option
                                                                key={index}
                                                                value={food}
                                                            >
                                                                {food}
                                                            </option>
                                                        ),
                                                    )}
                                                    {name &&
                                                        !filteredFoodNames.includes(
                                                            name,
                                                        ) && (
                                                            <option
                                                                key={name}
                                                                value={name}
                                                            >
                                                                {name}
                                                            </option>
                                                        )}
                                                </>
                                            )}
                                        </select>
                                    </div>
                                    {work_location === "常滑" ? (
                                        <div className={divMiddle}>
                                            <select
                                                value={floor}
                                                className={select2}
                                                onChange={(e) =>
                                                    handleFoodChange(
                                                        easyproId,
                                                        date,
                                                        name,
                                                        e.target.value,
                                                    )
                                                }
                                                disabled={fullyDenied}
                                            >
                                                {floors.map((floorOption) => (
                                                    <option
                                                        key={floorOption}
                                                        value={floorOption}
                                                    >
                                                        {floorOption}{" "}
                                                        {language.floor}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                    <div className={div9}>
                                        <input
                                            type="checkbox"
                                            checked={checkedIn}
                                            className="h-4 w-4"
                                            onChange={() =>
                                                checkboxClicked(easyproId, date)
                                            }
                                            disabled={
                                                !isToday || isBefore1145AM
                                            }
                                        />
                                    </div>
                                </div>
                            );
                        })}
                </div>
                <div className="my-4 h-1/6">
                    <div className={div10}>
                        <button className={button1} onClick={handleSubmission}>
                            {language.submit}
                        </button>
                    </div>
                </div>
            </div>

            {/* Render modal */}
            <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                message={language.modal.submission_successful}
            />
        </div>
    );
};

export default Foodselectionpage;
