import { jwtDecode } from "jwt-decode";
import * as api from "../api/index";
import {
    AUTH,
    LOGOUT,
    RESET_SUCCESS,
    START_LOADING,
    UPDATE_WORK_LOCATION,
} from "../constants/actionTypes";

import { DispatchErrorMessage } from "./helper";

export const signin =
    (id, password, navigate, language) => async (dispatch) => {
        try {
            dispatch({ type: START_LOADING });
            const response = await api.userLogin2(id, password);

            if (response.data.errorMessage) {
                DispatchErrorMessage(dispatch, response.data, language);
            } else {
                const token = response.data.data.token;
                const decodedData = jwtDecode(token);

                const { work_location, role, easyproId } = decodedData;

                dispatch({
                    type: AUTH,
                    data: { work_location, role, easyproId, token },
                });

                if (easyproId === "12345") {
                    navigate("/admin");
                } else {
                    navigate("/profile");
                }
            }
        } catch (error) {
            alert("Something went wrong.");
            console.log(error);
            // dispatch(SET_ERROR_MESSAGE("Login Failed. Please try again later"));
        }
    };

export const signup = (id, password, language) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });

        const response = await api.userRegistration2(id, password);
        if (response.data.errorMessage) {
            DispatchErrorMessage(dispatch, response.data, language);
        } else {
            dispatch({ type: AUTH, data: response.data, success: true });
            setTimeout(() => {
                dispatch({ type: RESET_SUCCESS });
            }, 2000);
        }
    } catch (error) {
        console.log(error);
    }
};

export const initAuth = () => async (dispatch) => {
    // Check if there's a token in local storage
    dispatch({ type: { START_LOADING } });
    const storedToken = localStorage.getItem("token");

    if (storedToken) {
        try {
            // Decode the token

            const response = await api.VerifyToken();

            if (response.data.errorMessage) {
                alert(response.data.errorMessage);
                dispatch({ type: LOGOUT });
            } else {
                const decodedToken = jwtDecode(storedToken);

                // Extract the required fields
                const { work_location, role, easyproId } = decodedToken;

                // Pass the required data into the reducer
                dispatch({
                    type: AUTH,
                    data: { work_location, role, easyproId },
                });
            }
        } catch (error) {
            alert(error.response.data.errorMessage);
            dispatch({ type: LOGOUT });
        }
    } else {
        dispatch({ type: LOGOUT });
    }
};

export const updateWorkLocation = (newWorkLocation) => ({
    type: UPDATE_WORK_LOCATION,
    data: newWorkLocation,
});

// Helper function
// export const DispatchErrorMessage = (dispatch, data, language) => {
//   // const language = useLanguage();
//   // Assuming data.errorType contains the error type
//   const errorType = data.errorType || "AUTH_FAIL"; // Provide a default if not available
//   const errorMessage = language.errors[data.errorMessage];
//   dispatch({ type: errorType, payload: errorMessage });

//   // Clear the error message after 3 seconds
//   setTimeout(() => {
//     dispatch({ type: errorType, payload: null });
//   }, 2000);
// };
