import { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { monthly_summary } from "../../actions/crud";
import BranchSelection from "../../components/BranchSelection";
import { tableStyle } from "../../tailwindClasses/Styles";
import FNWC from "../../utils/FNWC";

//* Months Addition
import MonthSelection from "../../components/MonthSelection";
import { getMonthOffset } from "../../utils/monthOffsetUtils";

function MonthlySummary() {
    const [currentBranch, setCurrentBranch] = useState("本社");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //* Months Addition
    const [monthValue, setMonthValue] = useState("this_month");
    const [monthOffset, setMonthOffset] = useState(
        getMonthOffset("this_month"),
    );
    const months = ["previous_month", "this_month", "next_month"];

    //* Months Addition
    const handleSelection = (e) => {
        const selectedMonth = e.target.value;
        setMonthValue(selectedMonth);
        setMonthOffset(getMonthOffset(selectedMonth));
    };

    const data = useSelector((state) => state.crud.data.monthlySummary);

    const branches = ["本社", "常滑", "知立", "島根", "名古屋", "東京", "博多"];

    useEffect(() => {
        dispatch(monthly_summary({ branch: currentBranch, monthOffset }));
    }, [dispatch, currentBranch, monthOffset]);

    const handleRowClick = (easyproId) => {
        navigate(`/admin/singleuserinfo/${easyproId}`);
    };

    const renderTableHeaders = () => {
        if (!data || data.length === 0) {
            return null;
        }

        const headers = [
            "easyproId",
            "氏名",
            ...Array.from(
                new Set(data.flatMap((item) => Object.keys(item.counts))),
            ),
        ];

        if (currentBranch === "島根") {
            headers.push("アゴ弁合計", "niconico合計");
        } else if (
            currentBranch === "名古屋" ||
            currentBranch === "東京" ||
            currentBranch === "博多"
        ) {
            headers.push(`${currentBranch}合計`);
        } else if (currentBranch === "本社" || currentBranch === "知立") {
            headers.push("たつえ合計金額", "玉清合計金額");
        } else {
            headers.push("たつえ合計金額", "とこなめ合計金額");
        }

        headers.push(
            "給与控除額",
            "会社負担額",
            ...Array.from(
                new Set(data.flatMap((item) => Object.keys(item.totalCounts))),
            ),
        );

        return (
            <tr>
                {headers.map((header) => (
                    <th key={header} className="border border-gray-300 p-2">
                        {header}
                    </th>
                ))}
            </tr>
        );
    };

    const calculateTotals = () => {
        const totals = {};

        // Initialize totals object with keys in the same order as renderTableRows
        const headers = [
            ...Array.from(
                new Set(data.flatMap((item) => Object.keys(item.counts))),
            ),
        ];

        if (currentBranch === "島根") {
            headers.push("たつえ合計", "常滑合計");
        } else if (currentBranch === "名古屋") {
            headers.push("たつえ合計");
        } else if (currentBranch === "東京") {
            headers.push("たつえ合計");
        } else if (currentBranch === "博多") {
            headers.push("たつえ合計");
        } else {
            headers.push("たつえ合計金額", "とこなめ合計金額");
        }

        headers.push(
            "島根合計金額",
            "給与控除額",
            ...Array.from(
                new Set(data.flatMap((item) => Object.keys(item.totalCounts))),
            ),
        );

        headers.forEach((header) => (totals[header] = 0));

        data.forEach((item) => {
            Object.keys(item.counts).forEach((foodName) => {
                totals[foodName] += item.counts[foodName];
            });

            if (currentBranch === "島根") {
                totals["たつえ合計"] += item.grandTotal["たつえ合計"];
                totals["常滑合計"] += item.grandTotal["常滑合計"];
            } else if (currentBranch === "名古屋") {
                totals["たつえ合計"] += item.grandTotal["たつえ合計"];
            } else if (currentBranch === "東京") {
                totals["たつえ合計"] += item.grandTotal["たつえ合計"];
            } else if (currentBranch === "博多") {
                totals["たつえ合計"] += item.grandTotal["たつえ合計"];
            } else {
                totals["たつえ合計金額"] += item.grandTotal["たつえ合計"];
                totals["とこなめ合計金額"] += item.grandTotal["常滑合計"];
            }

            if (
                item.employee_status === "外部" ||
                item.employee_status === "その他"
            ) {
                totals["島根合計金額"] += 0;
                totals["給与控除額"] +=
                    item.company_burden + item.salary_deduction;
            } else {
                totals["島根合計金額"] += item.salary_deduction;
                totals["給与控除額"] += item.company_burden;
            }

            // totals["島根合計金額"] += item.salary_deduction;
            //   totals["給与控除額"] += item.company_burden;

            Object.keys(item.totalCounts).forEach((totalCount) => {
                totals[totalCount] += item.totalCounts[totalCount];
            });
        });
        return totals;
    };

    const renderTableRows = () => {
        if (!data || data.length === 0) {
            return null;
        }

        // Render regular rows
        const regularRows = data.map((item, index) => (
            <tr
                key={index}
                className={tableStyle.tr}
                onClick={() => handleRowClick(item.easyproId)}
            >
                <td className={tableStyle.td}>{item.easyproId}</td>
                <td className={tableStyle.td}>{item.name}</td>
                {/* <td className={tableStyle.td}>{item.employee_status}</td> */}
                {Array.from(new Set(Object.keys(item.counts))).map(
                    (foodName) => (
                        <td key={foodName} className={tableStyle.td}>
                            {item.counts[foodName]}
                        </td>
                    ),
                )}
                {currentBranch === "島根" ? (
                    <>
                        <td className={tableStyle.td}>
                            {FNWC(item.grandTotal["たつえ合計"])}
                        </td>
                        <td className={tableStyle.td}>
                            {FNWC(item.grandTotal["常滑合計"])}
                        </td>
                    </>
                ) : currentBranch === "名古屋" ||
                  currentBranch === "東京" ||
                  currentBranch === "博多" ? (
                    <>
                        <td className={tableStyle.td}>
                            {FNWC(item.grandTotal["たつえ合計"])}
                        </td>
                    </>
                ) : (
                    <>
                        <td className={tableStyle.td}>
                            {FNWC(item.grandTotal["たつえ合計"])}
                        </td>
                        <td className={tableStyle.td}>
                            {FNWC(item.grandTotal["常滑合計"])}
                        </td>
                    </>
                )}
                {item.employee_status === "外部" ||
                item.employee_status === "その他" ? (
                    <>
                        <td className={tableStyle.td}>{FNWC(0)}</td>
                        <td className={tableStyle.td}>
                            {FNWC(item.company_burden + item.salary_deduction)}
                        </td>
                    </>
                ) : (
                    <>
                        <td className={tableStyle.td}>
                            {FNWC(item.salary_deduction)}
                        </td>
                        <td className={tableStyle.td}>
                            {FNWC(item.company_burden)}
                        </td>
                    </>
                )}
                {/* <td className={tableStyle.td}>{FNWC(item.salary_deduction)}</td>
        <td className={tableStyle.td}>{FNWC(item.company_burden)}</td> */}
                {Array.from(new Set(Object.keys(item.totalCounts))).map(
                    (totalCount) => (
                        <td key={totalCount} className={tableStyle.td}>
                            {FNWC(item.totalCounts[totalCount])}
                        </td>
                    ),
                )}
            </tr>
        ));

        // Calculate totals for the last row
        const totals = calculateTotals();

        // Render last row with totals
        const totalRow = (
            <tr>
                <td colSpan="2" className={tableStyle.td}>
                    Total
                </td>
                {Object.keys(totals).map((key) => (
                    <td key={key} className={tableStyle.td}>
                        {FNWC(totals[key])}
                    </td>
                ))}
            </tr>
        );

        // Return all rows including the last total row
        return [...regularRows, totalRow];
    };

    return (
        <div className="flex h-screen w-screen flex-col bg-gradient-to-r from-blue-200 to-cyan-200">
            <div className="flex h-1/6 w-full flex-row">
                <div className="flex h-full w-1/6">
                    <div className="mx-auto my-auto">
                        <BranchSelection
                            currentBranch={currentBranch}
                            setCurrentBranch={setCurrentBranch}
                            branches={branches}
                        />
                    </div>
                </div>
                <div className="h-full w-4/6"></div>
                <div className="flex h-full w-1/6 flex-col">
                    <div className="bg-red mx-auto my-auto flex h-auto w-auto flex-col">
                        <div className="mx-auto">
                            <MonthSelection
                                months={months}
                                onChange={handleSelection}
                                monthValue={monthValue}
                            />
                        </div>
                        <div className="mt-6 rounded bg-gradient-to-r from-cyan-400 to-blue-400 px-4 py-2 font-bold text-white hover:bg-gradient-to-bl">
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                table="table-to-xls"
                                filename={
                                    monthOffset.substring(0, 6) +
                                    "-月次集計-" +
                                    currentBranch
                                }
                                buttonText="エクスポート"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-5/6 w-full overflow-y-auto">
                <table className={tableStyle.table} id="table-to-xls">
                    <thead className={tableStyle.thead}>
                        {renderTableHeaders()}
                    </thead>
                    <tbody>{renderTableRows()}</tbody>
                </table>
            </div>
        </div>
    );
}

export default MonthlySummary;
