import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signin } from "../actions/auth";
import Telmic_Logo from "../assets/telmic_logo.png";
import { useTitle } from "../hooks/useTitle";
import useWindowSize from "../hooks/useWindowSize";
import { loginPage_Laptop, loginPage_Mobile } from "../tailwindClasses/Styles";

import { useLanguage } from "../hooks/useLanguage";

const Login = () => {
    const dispatch = useDispatch();
    const [id, setId] = useState(0);
    const [message, setMessage] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const language = useLanguage();
    useTitle(language.page_titles.login_page);
    let loginPageStyle;
    const { windowSize } = useWindowSize();

    if (windowSize.isMobile) {
        loginPageStyle = loginPage_Mobile;
    } else {
        loginPageStyle = loginPage_Laptop;
    }

    const {
        div1,
        div2,
        section1,
        div3,
        div4,
        img1,
        div5,
        div6,
        h1,
        label,
        input,
        button,
        p,
        last_div,
    } = loginPageStyle;

    const errors = useSelector((state) => state.auth.errors);

    const setTimedMessage = (msg, time = 2000) => {
        setMessage(msg);
        setTimeout(() => {
            setMessage("");
        }, time);
    };

    useEffect(() => {
        if (errors) {
            setMessage(errors);

            return () => setMessage("");
        }
    }, [errors]);

    const handleLogin = (e) => {
        e.preventDefault();

        if (!id || !password) {
            setTimedMessage(language.errors.all_fields_required);
            return;
        }

        if (isNaN(id)) {
            setTimedMessage(language.errors.id_mustbe_number);
            return;
        }

        // Dispatch the signin action
        dispatch(signin(id, password, navigate, language));
    };

    return (
        <div className={div1}>
            <div className={div2}>
                <section className={section1}>
                    <div className={div3}>
                        <div className={div4}>
                            <img
                                className={img1}
                                src={Telmic_Logo}
                                alt="logo"
                            />
                        </div>
                        <div className={div5}>
                            <div className={div6}>
                                <h1 className={h1}>
                                    {language.login_register_page.login_header}
                                </h1>
                                <div>
                                    <label htmlFor="email">
                                        {
                                            language.login_register_page
                                                .your_email
                                        }
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        className={input}
                                        // placeholder="name@company.com"
                                        required=""
                                        onChange={(e) => setId(e.target.value)}
                                    />
                                </div>
                                <div className="relative">
                                    <label htmlFor="password" className={label}>
                                        {language.login_register_page.password}
                                    </label>
                                    <input
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        name="password"
                                        id="password"
                                        placeholder="••••••••"
                                        className={input}
                                        required
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                    <button
                                        type="button"
                                        className="absolute inset-y-0 right-0 mr-3 mt-8"
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                    >
                                        {showPassword ? (
                                            <VisibilityOffIcon />
                                        ) : (
                                            <VisibilityIcon />
                                        )}
                                    </button>
                                </div>

                                <button
                                    className={button}
                                    onClick={handleLogin}
                                >
                                    {language.login_register_page.signin}
                                </button>
                                <p className={"text-center text-red-600"}>
                                    {message}
                                </p>
                                {/* {errors && <p className="text-red-600 text-center">{errors}</p>} */}

                                <p className={p}>
                                    {language.login_register_page.new_user}
                                </p>
                                <div
                                    className={last_div}
                                    onClick={() => navigate("/register")}
                                >
                                    {language.login_register_page.signup}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Login;
