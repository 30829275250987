import { useNavigate } from "react-router-dom";

function NotFound() {
    const navigate = useNavigate();
    return (
        <div className="flex h-screen w-screen flex-col">
            <div className="flex h-1/3 w-full flex-col items-start justify-end">
                <div className="mx-auto text-7xl">404</div>
                <div className="mx-auto my-2 text-4xl">
                    ページが見つかりません
                </div>
            </div>
            <div className="flex h-2/3 w-full">
                <div className="mx-auto mt-10">
                    <button
                        onClick={() => navigate("/")}
                        className="mb-2 me-2 rounded-full border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
                    >
                        ホームページへ
                    </button>
                </div>
            </div>
        </div>
    );
}

export default NotFound;
