// Helper function
export const DispatchErrorMessage = (dispatch, data, language) => {
    // Assuming data.errorType contains the error type
    const errorType = data.errorType || "AUTH_FAIL"; // Provide a default if not available
    const errorMessage = language.errors[data.errorMessage];
    dispatch({ type: errorType, payload: errorMessage });

    // Clear the error message after 3 seconds
    setTimeout(() => {
        dispatch({ type: errorType, payload: null });
    }, 2000);
};
