import { CloseButton, Input } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useDispatch, useSelector } from "react-redux";
import { delete_food_item, fetch_all_foods } from "../../actions/crud";
import LoadingPage from "../../components/LoadingPage";
import UpdateFoodModal from "../../components/UpdateFoodModal";
import { StandardBgColor, tableStyle } from "../../tailwindClasses/Styles";

//* Months Addition
import MonthSelection from "../../components/MonthSelection";
import { getMonthOffset } from "../../utils/monthOffsetUtils";

import BranchSelection from "../../components/BranchSelection";

const FoodHistory = () => {
    const [currentBranch, setCurrentBranch] = useState("全て");
    const [showDeleteEmoji, setShowDeleteEmoji] = useState(false); // State to toggle visibility of the delete emoji
    const [showUpdateEmoji, setShowUpdateEmoji] = useState(false);
    const [showModal, setShowModal] = useState(false); // State to control modal visibility

    const [inputValue, setInputValue] = useState("");

    const [foodDetails, setFoodDetails] = useState({
        easyproId: 0,
        name: "",
        userName: "",
        date: "",
    });

    const tableRef = useRef(null);

    //* Months Addition
    const [monthValue, setMonthValue] = useState("this_month");
    const [monthOffset, setMonthOffset] = useState(
        getMonthOffset("this_month"),
    );
    const months = ["previous_month", "this_month", "next_month"];

    const regex = /[^()]+(?=\()/;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetch_all_foods(monthOffset));
    }, [dispatch, monthOffset]);

    const handleCellClick = async (easyproId, date) => {
        // Handle cell click
        if (showDeleteEmoji) {
            try {
                dispatch(delete_food_item(easyproId, date));
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleUpdateClick = ({ easyproId, displayValue, userName, date }) => {
        setFoodDetails({
            easyproId: easyproId,
            name: displayValue,
            userName: userName,
            date: date,
        });
        setShowModal(true);
    };

    const fetchedData = useSelector((state) => state.crud.data.allFoods);

    if (!fetchedData) {
        return <LoadingPage />;
    }

    const allDates = fetchedData.flatMap(({ data }) =>
        data.map((item) => item.date),
    );
    const uniqueDates = allDates.filter(
        (date, index) => allDates.indexOf(date) === index,
    );

    const branches = [
        "全て",
        "本社",
        "常滑",
        "知立",
        "島根",
        "名古屋",
        "東京",
        "博多",
    ];

    //* Months Addition
    const handleSelection = (e) => {
        const selectedMonth = e.target.value;
        setMonthValue(selectedMonth);
        setMonthOffset(getMonthOffset(selectedMonth));
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value.trim());
    };

    const isEnglish = (str) => /^[a-zA-Z\s]+$/.test(str); // Check if the string contains only English letters and spaces

    const filteredData = fetchedData.filter((eachData) => {
        const inputLower = isEnglish(inputValue)
            ? inputValue.toLowerCase()
            : inputValue;
        const name = eachData.userInfo.name;
        const nameToCompare = isEnglish(name) ? name.toLowerCase() : name;

        return (
            String(eachData.easyproId).includes(inputLower) ||
            nameToCompare.includes(inputLower)
        );
    });

    // Decide which data to use for mapping
    const dataToMap = filteredData.length > 0 ? filteredData : fetchedData;

    return (
        <div className="h-screen w-screen bg-gradient-to-r from-blue-200 to-cyan-200">
            <div className="flex h-1/5 w-full flex-row">
                <div className="flex h-full w-1/6">
                    <div className="my-auto ml-6">
                        <BranchSelection
                            currentBranch={currentBranch}
                            setCurrentBranch={setCurrentBranch}
                            branches={branches}
                        />
                    </div>
                </div>
                <div className="flex h-full w-4/6">
                    <div className="mx-auto my-auto w-2/3">
                        <Input
                            size="md"
                            radius="xl"
                            value={inputValue}
                            placeholder="名前またはID"
                            onChange={handleInputChange}
                            rightSectionPointerEvents="all"
                            rightSection={
                                <CloseButton
                                    aria-label="Clear input"
                                    style={{
                                        display: inputValue
                                            ? undefined
                                            : "none",
                                    }}
                                    onClick={() => setInputValue("")}
                                />
                            }
                        />
                    </div>
                </div>
                <div className="flex h-full w-1/6 flex-col">
                    <div className="bg-red mx-auto my-auto flex h-auto w-auto flex-col space-y-4">
                        <div className="flex flex-row space-x-4">
                            <div className="mx-auto">
                                <button
                                    onClick={() =>
                                        setShowDeleteEmoji(!showDeleteEmoji)
                                    }
                                    className="ml-auto rounded bg-gradient-to-r from-cyan-400 to-blue-400 px-4 py-2 font-bold text-white hover:bg-gradient-to-bl"
                                >
                                    {showDeleteEmoji ? "戻る" : "削除"}
                                </button>
                            </div>
                            <div className="mx-auto">
                                <button
                                    onClick={() =>
                                        setShowUpdateEmoji(!showUpdateEmoji)
                                    }
                                    className="ml-auto rounded bg-gradient-to-r from-cyan-400 to-blue-400 px-4 py-2 font-bold text-white hover:bg-gradient-to-bl"
                                >
                                    {showUpdateEmoji ? "戻る" : "更新"}
                                </button>
                            </div>
                        </div>

                        <div className="mx-auto">
                            <MonthSelection
                                months={months}
                                onChange={handleSelection}
                                monthValue={monthValue}
                            />
                        </div>
                        <div className="rounded bg-gradient-to-r from-cyan-400 to-blue-400 px-4 py-2 text-center font-bold text-white hover:bg-gradient-to-bl">
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                table="table-to-xls"
                                sheet="table-to-xls"
                                filename={
                                    monthOffset.substring(0, 6) +
                                    "-食の歴史-" +
                                    currentBranch
                                }
                                buttonText="エクスポート"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5 flex h-screen flex-col">
                <div className="flex-grow overflow-auto">
                    <div className={`h-5/6 ${StandardBgColor}`}>
                        <table className={tableStyle.table} id="table-to-xls">
                            <thead className={tableStyle.thead} ref={tableRef}>
                                <tr>
                                    <th className={tableStyle.th}>EasyproId</th>
                                    <th className={tableStyle.th}>氏名</th>
                                    {uniqueDates.map((date) => (
                                        <th
                                            key={date}
                                            className={tableStyle.th}
                                        >
                                            {date.substring(5, 10)}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {dataToMap.map((eachData) => {
                                    const { data, easyproId, userInfo } =
                                        eachData;

                                    return (
                                        <tr
                                            key={easyproId}
                                            className={tableStyle.special_tr}
                                            tabIndex="0"
                                        >
                                            <td className="border px-2 py-4">
                                                {easyproId}
                                            </td>
                                            <td className="border px-2 py-4">
                                                {userInfo?.name}
                                            </td>
                                            {uniqueDates.map((date) => {
                                                const foodForDate =
                                                    data.find(
                                                        (item) =>
                                                            item.date === date,
                                                    )?.name || "";
                                                const displayValue =
                                                    currentBranch === "全て" ||
                                                    foodForDate.includes(
                                                        currentBranch,
                                                    )
                                                        ? foodForDate
                                                        : "";

                                                return (
                                                    <td
                                                        key={date}
                                                        className="border px-1 text-center"
                                                    >
                                                        {displayValue.match(
                                                            regex,
                                                        )}
                                                        <div
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                handleCellClick(
                                                                    easyproId,
                                                                    date,
                                                                )
                                                            }
                                                        >
                                                            {foodForDate &&
                                                                displayValue &&
                                                                showDeleteEmoji &&
                                                                "❌"}
                                                        </div>
                                                        <div
                                                            className="cursor-grab"
                                                            onClick={() =>
                                                                handleUpdateClick(
                                                                    {
                                                                        easyproId,
                                                                        displayValue,
                                                                        userName:
                                                                            userInfo.name,
                                                                        date,
                                                                    },
                                                                )
                                                            }
                                                        >
                                                            {showUpdateEmoji &&
                                                                "🔄️"}
                                                        </div>
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className={`h-1/6 ${StandardBgColor}`}></div>
                </div>
            </div>
            <UpdateFoodModal
                showModal={showModal}
                data={foodDetails}
                setShowModal={setShowModal}
                message={"Update User Food"}
            />
        </div>
    );
};

export default FoodHistory;
