import { useState } from "react";

import { useDispatch } from "react-redux";
import { generate_food } from "../../actions/crud";
import Modal from "../../components/Modal";
import MonthSelection from "../../components/MonthSelection";
import { useLanguage } from "../../hooks/useLanguage";
import { getMonthOffset } from "../../utils/monthOffsetUtils";

function GenerateFood() {
    const [monthValue, setMonthValue] = useState("this_month");
    const [monthOffset, setMonthOffset] = useState(
        getMonthOffset("this_month"),
    );
    const [showModal, setShowModal] = useState(false); // State for controlling the visibility of the modal

    const dispatch = useDispatch();
    const language = useLanguage();

    const months = [
        "previous_month",
        "this_month",
        "next_month",
        "next_next_month",
        "next_next_next_month",
    ];

    const handleSelection = (e) => {
        const selectedMonth = e.target.value;
        setMonthValue(selectedMonth);
        setMonthOffset(getMonthOffset(selectedMonth));
    };

    // handle submit function
    const handleSubmit = () => {
        const customMonthOffset = monthOffset
            .split("-")
            .slice(0, 2)
            .join("-")
            .replace(/-(\d)(?=\D|$)/g, "-0$1");
        dispatch(generate_food(monthOffset));

        // Show the modal after submission
        setShowModal(true);
    };

    return (
        <div className="flex h-96 w-full flex-row bg-gradient-to-r from-blue-200 to-cyan-200">
            <div className="flex h-full w-1/6">
                <div className="mx-auto mt-10">
                    <MonthSelection
                        months={months}
                        onChange={handleSelection}
                        monthValue={monthValue}
                    />
                </div>
            </div>
            <div className="h-full w-4/6">
                <div className="flex h-full w-full flex-col items-center">
                    <div className="mt-10 text-3xl">
                        {language.food_generation_page.selected_month} ::
                        {"\u00A0".repeat(3)}
                        {monthOffset
                            .split("-")
                            .slice(0, 2)
                            .join("-")
                            .replace(/-(\d)(?=\D|$)/g, "-0$1")}
                    </div>

                    <div className="mt-40 text-3xl">
                        <button
                            className="mb-2 me-2 rounded-full bg-blue-500 px-5 py-2.5 text-center text-2xl font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-yellow-900"
                            onClick={handleSubmit}
                        >
                            {language.food_generation_page.generate_food}
                        </button>
                    </div>
                </div>
            </div>
            <div className="h-full w-1/6"></div>
            <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                message={language.food_generation_page.data_submission_thank} // Pass the message to the modal
            />
        </div>
    );
}

export default GenerateFood;
