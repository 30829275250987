export const INSERT_FOOD = "INSERT_FOOD";

export const ALL_EMP_INFORMATION = "ALL_EMP_INFORMATION";

export const FETCH_SINGLE_USER_INFO = "FETCH_SINGLE_USER_INFO";
export const DELETE_USER = "DELETE_USER";
export const INSERT_USER = "INSERT_USER";
export const UPDATE_USER = "UPDATE_USER";
export const FETCH_SINGLE_USER_FOOD = "FETCH_SINGLE_USER_FOOD";
export const FETCH_ALL_FOODS = "FETCH_ALL_FOODS";
export const DELETE_FOOD_ITEM = "DELETE_FOOD_ITEM";
export const DAILY_FOOD_COUNTS = "DAILY_FOOD_COUNTS";
export const FETCH_SINGLE_USER_INFO_BY_ADMIN =
    "FETCH_SINGLE_USER_INFO_BY_ADMIN";
export const MONTHLY_SUMMARY = "MONTHLY_SUMMARY";
export const CHECKED_IN_DATA = "CHECKED_IN_DATA";
export const DEPARTMENT_WISE_DATA = "DEPARTMENT_WISE_DATA";

export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const END_LOADING = "END_LOADING";
export const START_LOADING = "START_LOADING";
export const SET_USER_INFO = "SET_USER_INFO";
export const RESET_SUCCESS = "RESET_SUCCESS";

export const UPDATE_WORK_LOCATION = "UPDATE_WORK_LOCATION";

export const DATE_CHANGE = "DATE_CHANGE";

export const FETCH_PERMISSION = "FETCH_PERMISSION";
export const UPDATE_PERMISSION = "UPDATE_PERMISSION";

//

export const GENERATE_FOODS = "GENERATE_FOODS";
export const UPDATE_FOODS = "UPDATE_FOODS";

//

export const UPDATE_CHECKBOX_BY_ADMIN = "UPDATE_CHECKBOX_BY_ADMIN";
export const SUBMIT_CHECKBOX_DELIVERY_STAFF = "SUBMIT_CHECKBOX_DELIVERY_STAFF";
export const FETCH_CHECKBOX_DELIVERY_STAFF = "FETCH_CHECKBOX_DELIVERY_STAFF";
export const UPDATE_CHECKBOX_DELIVERY_STAFF = "UPDATE_CHECKBOX_DELIVERY_STAFF";

export const TRIGGER_TODAY_FOOD_COUNT = "TRIGGER_TODAY_FOOD_COUNT";

//Errors

export const AUTH_FAIL = "AUTH_FAIL";
export const TOKEN_FAIL = "TOKEN_FAIL";
export const DATABASE_FAIL = "DATABASE_FAIL";
