import MyImage from "../assets/Gradient_builder_2.jpeg";
import { Food_Data } from "../data/FoodData";

const FoodInformation = () => {
    return (
        <div
            className="flex min-h-screen flex-wrap justify-around gap-6 bg-gray-200 bg-cover bg-no-repeat p-20"
            style={{ backgroundImage: `url(${MyImage})` }}
        >
            {Food_Data.map((detail) => {
                const { id, name, description, image } = detail;
                return (
                    <div
                        key={id}
                        className="sm:flex-basis-1/2 md:flex-basis-1/3 lg:flex-basis-1/4 mx-2 mb-2 mt-6 w-full max-w-xs flex-grow bg-slate-50 shadow-md transition-transform duration-300 hover:scale-105 hover:shadow-lg"
                    >
                        <div className="cursor-pointer">
                            <img
                                className="h-64 w-full bg-contain"
                                src={image}
                                alt="bento"
                            />
                            <div className="p-4">
                                <h5 className="mb-2 text-lg font-bold">
                                    {name}
                                </h5>
                                <p className="h-10 overflow-hidden text-sm text-gray-600">
                                    {description}
                                </p>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default FoodInformation;
