import { createBrowserRouter } from "react-router-dom";

import { FoodInformation, Login, RegisterPage } from "@Pages";

import NotFound from "@components/NotFound.js";
import Foodselectionpage from "@user/FoodSelectionPage.js";
import Profile from "@user/Profile.js";
import AuthLayout from "./AuthLayout.js";
import Layout from "./Layout.js";

import {
    CheckedInStatus,
    DailyCount,
    DeliveryStaffCheckbox,
    DeptWise,
    EmployeeInformation,
    FoodHistory,
    MonthlySummary,
    Permission,
    SingleUserInfo,
} from "@Admin";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "/",
                element: (
                    <AuthLayout authentication={false}>
                        <Login />
                    </AuthLayout>
                ),
            },
            {
                path: "/register",
                element: (
                    <AuthLayout authentication={false}>
                        <RegisterPage />
                    </AuthLayout>
                ),
            },
            {
                path: "/profile",
                element: (
                    <AuthLayout authentication isEmployee>
                        <Profile />
                    </AuthLayout>
                ),
            },
            {
                path: "/foodselection",
                element: (
                    <AuthLayout authentication isEmployee>
                        <Foodselectionpage />
                    </AuthLayout>
                ),
            },
            {
                path: "/admin",
                element: (
                    <AuthLayout authentication isAdmin>
                        <FoodInformation />
                    </AuthLayout>
                ),
            },
            {
                path: "/admin/monthly_summary",
                element: (
                    <AuthLayout authentication isAdmin>
                        <MonthlySummary />
                    </AuthLayout>
                ),
            },
            {
                path: "/admin/food_history",
                element: (
                    <AuthLayout authentication isAdmin>
                        <FoodHistory />
                    </AuthLayout>
                ),
            },
            {
                path: "/admin/employee",
                element: (
                    <AuthLayout authentication isAdmin>
                        <EmployeeInformation />
                    </AuthLayout>
                ),
            },
            {
                path: "/admin/dailycount",
                element: (
                    <AuthLayout authentication isAdmin>
                        <DailyCount />
                    </AuthLayout>
                ),
            },
            {
                path: "/admin/singleuserinfo/:id",
                element: (
                    <AuthLayout authentication isAdmin>
                        <SingleUserInfo />
                    </AuthLayout>
                ),
            },
            {
                path: "/admin/deptwise",
                element: (
                    <AuthLayout authentication isAdmin>
                        <DeptWise />
                    </AuthLayout>
                ),
            },
            {
                path: "/admin/checkedin",
                element: (
                    <AuthLayout authentication isAdmin>
                        <CheckedInStatus />
                    </AuthLayout>
                ),
            },
            {
                path: "/admin/permission",
                element: (
                    <AuthLayout authentication isAdmin>
                        <Permission />
                    </AuthLayout>
                ),
            },
            {
                path: "/admin/delivery-staff",
                element: (
                    <AuthLayout authentication isAdmin>
                        <DeliveryStaffCheckbox />
                    </AuthLayout>
                ),
            },

            {
                path: "*",
                element: <NotFound />,
            },
        ],
    },
]);
