import AdminNavbar from "@components/AdminNavbar";
import UserNavbar from "@components/UserNavbar";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

function Layout() {
    const authData = useSelector((state) => state.auth.authData);
    let role;
    if (authData) {
        role = authData?.role; // removed the 'const' keyword
    }

    return (
        <>
            {(!role || role === "employee") && <UserNavbar />}
            {role === "admin" && <AdminNavbar />}
            <Outlet />
        </>
    );
}

export default Layout;
