import AutorenewIcon from "@mui/icons-material/Autorenew";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useDispatch, useSelector } from "react-redux";
import { delete_user, fetch_all_emp_information } from "../../actions/crud";
import LoadingPage from "../../components/LoadingPage";
import { useLanguage } from "../../hooks/useLanguage";

import InsertUserModal from "../../components/InsertUserModal";
import { buttonStyle, tableStyle } from "../../tailwindClasses/Styles";

import { CloseButton, Input } from "@mantine/core";
import GenerateFood from "./GenerateFood";

// Employee Information function
function EmployeeInformation() {
    const [workLocationFilter, setWorkLocationFilter] = useState("みんな");
    const dispatch = useDispatch();
    const language = useLanguage();
    const [removeUser, setRemoveUser] = useState(false);
    const [updateUser, setUpdateUser] = useState(false);
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [modalMessage, setModalMessage] = useState("");
    const [inputValue, setInputValue] = useState("");

    // State variables to manage user details for adding and updating users
    const [userDetails, setUserDetails] = useState({
        easyproId: 0,
        name: "",
        employee_status: "",
        company_group: "",
        team: "",
        work_location: "",
    });

    useEffect(() => {
        dispatch(fetch_all_emp_information());
    }, [dispatch, workLocationFilter]);

    const details = useSelector((state) => state.crud.data.allEmpInformation);

    // Delete User function
    const delUser = (id) => {
        const easyproId = id;
        dispatch(delete_user(easyproId));
    };

    const branches = [
        "みんな",
        "本社",
        "常滑",
        "知立",
        "島根",
        "名古屋",
        "東京",
        "博多",
    ];

    const filterByWorkLocation = (location) => {
        setWorkLocationFilter(location);
    };

    const getFilteredDetails = () => {
        if (workLocationFilter === "みんな") {
            return details;
        } else {
            return details.filter(
                (detail) => detail.work_location === workLocationFilter,
            );
        }
    };

    // Function to handle adding user
    const handleAddUser = () => {
        setUserDetails({
            easyproId: 0,
            name: "",
            employee_status: "",
            company_group: "",
            team: "",
            work_location: "",
        });
        setShowModal(true);
        setModalMessage("Add User");
    };

    // Function to handle updating user
    const handleUpdateUser = (detail) => {
        setUserDetails(detail);
        setShowModal(true);
        setModalMessage("Update User");
    };

    if (!details) {
        return <LoadingPage />;
    }

    console.log("The fetched filtered data is", getFilteredDetails());
    const handleInputChange = (e) => {
        setInputValue(e.target.value.trim());
    };

    const isEnglish = (str) => /^[a-zA-Z\s]+$/.test(str); // Check if the string contains only English letters and spaces

    const filteredData = getFilteredDetails().filter((eachData) => {
        const inputLower = isEnglish(inputValue)
            ? inputValue.toLowerCase()
            : inputValue;
        const name = eachData.name;
        const nameToCompare = isEnglish(name) ? name.toLowerCase() : name;

        return (
            String(eachData.easyproId).includes(inputLower) ||
            nameToCompare.includes(inputLower)
        );
    });

    // Decide which data to use for mapping
    const dataToMap =
        filteredData.length > 0 ? filteredData : getFilteredDetails();

    return (
        <div className="flex h-auto min-h-screen w-screen flex-col overflow-y-auto bg-gradient-to-r from-blue-200 to-cyan-200">
            <div className="mt-10 flex h-1/5 w-full flex-row">
                <div className="flex h-full w-1/6 flex-col">
                    <div className="w-1/2">
                        <div className="ml-4 mt-4">
                            <select
                                className="my-auto ml-5 h-10 appearance-none rounded-full border border-gray-300 bg-white px-5 text-center text-gray-600 hover:border-gray-400 focus:outline-none"
                                value={workLocationFilter}
                                onChange={(e) =>
                                    filterByWorkLocation(e.target.value)
                                }
                            >
                                {branches.map((branch) => (
                                    <option key={branch} value={branch}>
                                        {branch}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="ml-6">
                            <div className="mt-6 rounded bg-gradient-to-r from-cyan-400 to-blue-400 px-4 py-2 font-bold text-white hover:bg-gradient-to-bl">
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    table="table-to-xls"
                                    filename={workLocationFilter + "のデータ"}
                                    buttonText="エクスポート"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-1/2"></div>
                </div>
                <div className="flex h-full w-4/6 flex-col space-y-6">
                    <div className="mx-auto my-auto font-mono text-5xl tracking-wider text-amber-950">
                        {
                            language.employee_information_page
                                .employee_information
                        }
                    </div>
                    <div>
                        <Input
                            size="md"
                            radius="xl"
                            value={inputValue}
                            placeholder="名前またはID"
                            onChange={handleInputChange}
                            rightSectionPointerEvents="all"
                            rightSection={
                                <CloseButton
                                    aria-label="Clear input"
                                    style={{
                                        display: inputValue
                                            ? undefined
                                            : "none",
                                    }}
                                    onClick={() => setInputValue("")}
                                />
                            }
                        />
                    </div>
                </div>
                <div className="flex h-full w-1/6">
                    <div className="my-auto ml-auto mr-6 flex flex-col">
                        <button
                            className={buttonStyle.special_button}
                            onClick={handleAddUser}
                        >
                            {language.employee_information_page.add_user}
                        </button>
                        <button
                            className={buttonStyle.special_button}
                            onClick={() =>
                                setRemoveUser((prevState) => !prevState)
                            }
                        >
                            {language.employee_information_page.remove_user}
                        </button>
                        <button
                            className={buttonStyle.special_button}
                            onClick={() =>
                                setUpdateUser((prevState) => !prevState)
                            }
                        >
                            {language.employee_information_page.update_user}
                        </button>
                    </div>
                </div>
            </div>
            <div className="mt-12 flex h-3/5 w-full">
                <div className="mx-auto w-5/6">
                    <table className={tableStyle.table} id="table-to-xls">
                        <thead className={tableStyle.thead}>
                            <tr>
                                <th className={tableStyle.th}>S. N.</th>
                                <th className={tableStyle.th}>EasyPro Id</th>
                                <th className={tableStyle.th}>
                                    {language.employee_profile.name}
                                </th>
                                <th className={tableStyle.th}>
                                    {
                                        language.employee_profile
                                            .employment_status
                                    }
                                </th>
                                <th className={tableStyle.th}>
                                    {language.employee_profile.group}
                                </th>
                                <th className={tableStyle.th}>
                                    {language.employee_profile.team}
                                </th>
                                <th className={tableStyle.th}>
                                    {language.employee_profile.work_location}
                                </th>
                                {removeUser && (
                                    <th className={tableStyle.th}>
                                        {language.remove_user}{" "}
                                    </th>
                                )}
                                {updateUser && (
                                    <th className={tableStyle.th}>
                                        Update User
                                    </th>
                                )}
                            </tr>
                        </thead>
                        {dataToMap.map((detail, index) => {
                            const {
                                _id,
                                easyproId,
                                name,
                                employee_status,
                                company_group,
                                team,
                                work_location,
                            } = detail;
                            return (
                                <tbody key={_id}>
                                    <tr align="center">
                                        <td className={tableStyle.td}>
                                            {index + 1}
                                        </td>
                                        <td className={tableStyle.td}>
                                            {easyproId}
                                        </td>
                                        <td className={tableStyle.td}>
                                            {name}
                                        </td>
                                        <td className={tableStyle.td}>
                                            {employee_status}
                                        </td>
                                        <td className={tableStyle.td}>
                                            {company_group}
                                        </td>
                                        <td className={tableStyle.td}>
                                            {team}
                                        </td>
                                        <td className={tableStyle.td}>
                                            {work_location}
                                        </td>
                                        {removeUser && (
                                            <td className={tableStyle.td}>
                                                <button
                                                    onClick={() => {
                                                        delUser(easyproId);
                                                    }}
                                                >
                                                    <DeleteRoundedIcon
                                                        sx={{ color: "red" }}
                                                    />
                                                </button>
                                            </td>
                                        )}
                                        {updateUser && (
                                            <td className={tableStyle.td}>
                                                <button
                                                    onClick={() =>
                                                        handleUpdateUser(detail)
                                                    }
                                                >
                                                    <AutorenewIcon
                                                        sx={{ color: "green" }}
                                                    />
                                                </button>
                                            </td>
                                        )}
                                    </tr>
                                </tbody>
                            );
                        })}
                    </table>
                </div>
            </div>
            {/* Render modal */}

            <div className="h-1/5 w-full">
                <GenerateFood />
            </div>

            {showModal && (
                <InsertUserModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    message={modalMessage}
                    data={userDetails}
                />
            )}
        </div>
    );
}

export default EmployeeInformation;
