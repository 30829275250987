// Dropdown.js
import { useLanguage } from "../hooks/useLanguage";

const MonthSelection = ({ months, onChange, monthValue = "this_month" }) => {
    const language = useLanguage();

    return (
        <select
            value={monthValue}
            onChange={onChange}
            className="mx-auto h-10 appearance-none rounded-full border border-gray-300 bg-white px-5 text-center hover:border-gray-400"
        >
            {months.map((month) => (
                <option key={month} value={month}>
                    {language.months[month]}
                </option>
            ))}
        </select>
    );
};

export default MonthSelection;
