import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { insert_user, update_user } from "../actions/crud";
import { useLanguage } from "../hooks/useLanguage";

const InsertUserModal = ({ showModal, setShowModal, message, data }) => {
    const [errorMessage, setErrorMessage] = useState("");

    const dispatch = useDispatch();
    let isDisabled;
    if (message === "Add User") {
        isDisabled = false;
    } else if (message === "Update User") {
        isDisabled = true;
    }

    const errors = useSelector((state) => state.auth?.errors);

    const language = useLanguage();

    const branches = ["本社", "常滑", "知立", "島根", "名古屋", "東京", "博多"];

    const employee_status_list = ["取締役", "社員", "AP", "外部", "その他"];

    const setTimedMessage = (msg, time = 2000) => {
        setErrorMessage(msg);
        setTimeout(() => {
            setErrorMessage("");
        }, time);
    };

    // State to manage form input values
    const [formData, setFormData] = useState({
        easyproId: data.easyproId,
        name: data.name,
        employee_status: employee_status_list.includes(data.employee_status)
            ? data.employee_status
            : "社員",
        company_group: data.company_group,
        team: data.team,
        work_location: branches.includes(data.work_location)
            ? data.work_location
            : "常滑",
    });

    // Function to handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const inputClass =
        "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500";
    const buttonClass =
        "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 font-medium rounded-lg text-sm w-1/6 px-5 py-2.5 text-center";
    // If showModal is false, return null (modal is hidden)
    if (!showModal) return null;

    const handleSubmit = () => {
        // Check if any of the formData fields (excluding team and company_group) are empty
        const isAnyFieldEmpty = Object.keys(formData)
            .filter((key) => key !== "team" && key !== "company_group")
            .some((field) => formData[field] === "");

        // Check if easyproId is not a number
        const isEasyproIdNotANumber = isNaN(formData.easyproId);

        if (isAnyFieldEmpty) {
            setTimedMessage("すべてのフィールドに入力する必要があります");
        } else if (isEasyproIdNotANumber) {
            setTimedMessage("Easypro Id should be a number.");
        } else {
            if (message === "Add User") {
                dispatch(insert_user(formData, language));
            } else {
                dispatch(update_user(formData, language));
            }
            if (errorMessage) {
                setShowModal(true);
            } else {
                setShowModal(false);
            }
        }
    };

    return (
        <div
            id="popup-modal"
            tabIndex="-1"
            className="fixed inset-0 flex h-auto w-auto items-center justify-center"
        >
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="relative w-1/3 p-4">
                <div className="relative flex flex-col rounded-lg bg-white shadow-lg">
                    <div className="mx-auto my-5 text-2xl">{message}</div>
                    <div>
                        <form className="mx-auto my-5 w-5/6">
                            <div className="mb-5">
                                <div>EasyproID</div>
                                <input
                                    className={inputClass}
                                    name="easyproId"
                                    value={formData.easyproId}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                            </div>
                            <div className="mb-5">
                                <div>{language.employee_profile.name}</div>
                                <input
                                    className={inputClass}
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    disabled={isDisabled}
                                />
                            </div>
                            <div className="mb-5">
                                <div>
                                    {
                                        language.employee_profile
                                            .employment_status
                                    }
                                </div>
                                <select
                                    className={inputClass}
                                    name="employee_status"
                                    value={formData.employee_status}
                                    onChange={handleInputChange}
                                >
                                    {employee_status_list.map((s) => (
                                        <option key={s} value={s}>
                                            {s}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-5">
                                <div>{language.employee_profile.group}</div>
                                <input
                                    className={inputClass}
                                    name="company_group"
                                    value={formData.company_group}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-5">
                                <div>{language.employee_profile.team}</div>
                                <input
                                    className={inputClass}
                                    name="team"
                                    value={formData.team}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-5">
                                <div>
                                    {language.employee_profile.work_location}
                                </div>
                                <select
                                    className={inputClass}
                                    name="work_location"
                                    value={formData.work_location}
                                    onChange={handleInputChange}
                                >
                                    {branches.map((branch) => (
                                        <option key={branch} value={branch}>
                                            {branch}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {errorMessage && (
                                <div className="text-red-400">
                                    {errorMessage}
                                </div>
                            )}

                            <div className="flex justify-between">
                                <button
                                    className={buttonClass}
                                    type="button"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                                <button
                                    type="button" // Change type to button to prevent form submission
                                    onClick={() => setShowModal(false)}
                                    className={buttonClass}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InsertUserModal;
