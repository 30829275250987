import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetch_single_user_info_by_admin } from "../../actions/crud";
import LoadingPage from "../../components/LoadingPage";
import { useLanguage } from "../../hooks/useLanguage";
import { Profile_Style_Laptop, tableStyle } from "../../tailwindClasses/Styles";

//* Months Addition
import MonthSelection from "../../components/MonthSelection";
import { getMonthOffset } from "../../utils/monthOffsetUtils";

function SingleUserInfo() {
    const dispatch = useDispatch();
    const language = useLanguage();

    const { thead, table, th, td, tr } = tableStyle;

    //* Months Addition
    const [monthValue, setMonthValue] = useState("this_month");
    const [monthOffset, setMonthOffset] = useState(
        getMonthOffset("this_month"),
    );
    const months = ["previous_month", "this_month", "next_month"];

    //* Months Addition
    const handleSelection = (e) => {
        const selectedMonth = e.target.value;
        setMonthValue(selectedMonth);
        setMonthOffset(getMonthOffset(selectedMonth));
    };

    let { id } = useParams();

    const userData = useSelector(
        (state) => state.crud.data.singleUserDataByAdmin,
    );

    useEffect(() => {
        dispatch(fetch_single_user_info_by_admin({ id, monthOffset }));
    }, [dispatch, id, monthOffset]);

    if (!userData) {
        return <LoadingPage />;
    }

    const regex = /\(([^)]+)\)/;
    return (
        <div className="flex h-screen w-screen flex-col overflow-x-auto bg-gradient-to-r from-blue-200 to-cyan-200">
            <div className="flex h-1/6 w-full flex-row">
                <div className="h-full w-1/6"></div>
                <div className="h-full w-4/6"></div>
                <div className="flex h-full w-1/6">
                    <div className="mx-auto my-auto">
                        <MonthSelection
                            months={months}
                            onChange={handleSelection}
                            monthValue={monthValue}
                        />
                    </div>
                </div>
            </div>
            <div className="flex h-5/6 w-full">
                <div className="h-full w-1/2 overflow-x-auto">
                    <table className={table}>
                        <thead className={thead}>
                            <tr>
                                <th className={th}>EasyproId</th>
                                <th className={th}>氏名</th>
                                <th className={th}>勤務地</th>
                                <th className={th}>食べ物</th>
                                <th className={th}>注文場所</th>
                                <th className={th}>日付</th>
                                <th className={th}>チェックイン</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userData &&
                                userData.singleUserFoods.map((food, index) => (
                                    <tr key={index}>
                                        <td className={td}>
                                            {userData.userInfo.easyproId}
                                        </td>
                                        <td className={td}>
                                            {userData.userInfo.name}
                                        </td>
                                        <td className={td}>
                                            {userData.userInfo.work_location}
                                        </td>
                                        <td className={td}>{food.name}</td>
                                        <td className={td}>
                                            {food.name.match(regex)
                                                ? food.name.match(regex)[1]
                                                : ""}
                                        </td>
                                        <td className={td}>{food.date}</td>
                                        <td className={td}>
                                            <input
                                                type="checkbox"
                                                checked={food.checkedIn}
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex h-full w-1/2 flex-col">
                    <div className="h-1/2 w-full">
                        <div className={Profile_Style_Laptop.div5}>
                            <div className={Profile_Style_Laptop.div6}>
                                <span className="text-3xl">
                                    {language.profile}
                                </span>
                            </div>
                        </div>
                        <div className="flex h-full w-full flex-row bg-gray-300">
                            <div className="h-full w-1/2">
                                <div className="mt-6 flex flex-col items-end space-y-4 pr-4 text-2xl">
                                    <div>
                                        {language.employee_profile.easyproid} ::
                                    </div>
                                    <div>
                                        {language.employee_profile.name} ::
                                    </div>
                                    <div>
                                        {" "}
                                        {
                                            language.employee_profile
                                                .employment_status
                                        }{" "}
                                        ::
                                    </div>
                                    <div>
                                        {language.employee_profile.team} ::
                                    </div>
                                    <div>
                                        {language.employee_profile.group} ::
                                    </div>
                                    <div>
                                        {
                                            language.employee_profile
                                                .work_location
                                        }{" "}
                                        ::
                                    </div>
                                </div>
                            </div>
                            <div className="h-full w-1/2">
                                <div className="mt-6 flex flex-col items-start space-y-4 pl-4 text-2xl">
                                    <div>
                                        {" "}
                                        {userData.userInfo.easyproId ||
                                            "\u00A0"}
                                    </div>
                                    <div>
                                        {" "}
                                        {userData.userInfo.name || "\u00A0"}
                                    </div>
                                    <div>
                                        {" "}
                                        {userData.userInfo.employee_status ||
                                            "\u00A0"}
                                    </div>
                                    <div>
                                        {" "}
                                        {userData.userInfo.company_group ||
                                            "\u00A0"}
                                    </div>
                                    <div>
                                        {userData.userInfo.team || "\u00A0"}
                                    </div>
                                    <div>
                                        {" "}
                                        {userData.userInfo.work_location ||
                                            "\u00A0"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-1/2 w-full">
                        <table className={table}>
                            <thead>
                                <tr className={tr}>
                                    <th className={th}>支店</th>
                                    <th className={th}>数</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userData &&
                                    Object.entries(userData.branchCounts).map(
                                        ([branch, count]) => (
                                            <tr key={branch} className={tr}>
                                                <td className={td}>{branch}</td>
                                                <td className={td}>{count}</td>
                                            </tr>
                                        ),
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleUserInfo;
