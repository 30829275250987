// BranchSelection.js

function BranchSelection({ currentBranch, setCurrentBranch, branches }) {
    return (
        <select
            value={currentBranch}
            onChange={(e) => setCurrentBranch(e.target.value)}
            className="h-10 appearance-none rounded-full border border-gray-300 bg-white px-5 text-center hover:border-gray-400"
        >
            {branches.map((branch) => (
                <option key={branch} value={branch}>
                    {branch}
                </option>
            ))}
        </select>
    );
}

export default BranchSelection;
