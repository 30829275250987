import { useEffect, useState } from "react";
// import { dateData } from "./FoodHistoryData";
import { useDispatch, useSelector } from "react-redux";
import { checkIn_data, update_checkbox_by_admin } from "../../actions/crud";
import CalendarComponent from "../../components/CalendarComponent";
import LoadingPage from "../../components/LoadingPage";
import { tableStyle } from "../../tailwindClasses/Styles";

function CheckedInStatus() {
    const branches = ["常滑", "本社", "知立", "島根", "名古屋", "東京", "博多"];
    const floors = [1, 2];
    const [branch, setBranch] = useState(branches[0]);
    const [floor, setFloor] = useState(1);

    const dispatch = useDispatch();
    const currentDate = useSelector((state) => state.crud.currentDate);

    useEffect(() => {
        dispatch(checkIn_data(currentDate));
    }, [currentDate, floor, dispatch]);

    const dateData = useSelector((state) => state.crud.data.checkInData);

    if (!dateData) {
        return <LoadingPage />;
    }

    const filteredData =
        branch === "常滑"
            ? dateData[branch].filter(
                  (item) => Number(item.floor) === Number(floor),
              )
            : dateData[branch] || [];

    const handleBranchChange = (selectedBranch) => {
        setBranch(selectedBranch);
        setFloor(1); // Reset floor when switching branches
    };

    const handleFloorChange = (selectedFloor) => {
        setFloor(selectedFloor);
    };

    const handleCheckboxClick = ({ _id, date }) => {
        date = new Date(date).toISOString().split("T")[0];
        dispatch(update_checkbox_by_admin({ _id, date, branch }));
    };
    return (
        <div className="flex h-screen w-screen flex-row overflow-y-auto bg-gradient-to-r from-blue-200 to-cyan-200">
            <div className="flex h-full w-1/6 flex-row">
                <div className="mt-40 flex w-1/2">
                    <select
                        className="mx-auto h-10 appearance-none rounded-full border border-gray-300 bg-white px-5 text-center text-gray-600 hover:border-gray-400 focus:outline-none"
                        onChange={(event) =>
                            handleBranchChange(event.target.value)
                        }
                    >
                        {branches.map((b) => (
                            <option key={b} value={b} className="bg-gray-200">
                                {b}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="my-4 mt-40 flex w-1/2">
                    {branch === "常滑" && (
                        <select
                            className="mx-auto h-10 appearance-none rounded-full border border-gray-300 bg-white px-5 text-center text-gray-600 hover:border-gray-400 focus:outline-none"
                            onChange={(event) =>
                                handleFloorChange(event.target.value)
                            }
                        >
                            {floors.map((f) => (
                                <option
                                    key={f}
                                    value={f}
                                    className={`bg-gray-200 ${
                                        floor === f
                                            ? "bg-red-500 text-white"
                                            : ""
                                    }`}
                                >
                                    {f}階
                                </option>
                            ))}
                        </select>
                    )}
                </div>
            </div>
            <div className="flex h-full w-4/6 flex-col">
                <div className="flex h-1/6 w-full">
                    <div className="mx-auto my-auto font-serif text-3xl font-medium tracking-wider">
                        日付 :: {currentDate}
                    </div>
                </div>
                <div className="h-5/6 w-full">
                    <div className="mx-auto w-5/6">
                        <table className={tableStyle.table}>
                            <thead className={tableStyle.thead}>
                                <tr>
                                    <th className={tableStyle.th}>S. N.</th>
                                    <th className={tableStyle.th}>EasyproId</th>
                                    <th className={tableStyle.th}>氏名</th>
                                    <th className={tableStyle.th}>食べ物</th>
                                    {branch === "常滑" && (
                                        <th className={tableStyle.th}>階</th>
                                    )}
                                    <th className={tableStyle.th}>
                                        チェックイン
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((item, index) => (
                                    <tr
                                        key={item._id}
                                        className={tableStyle.tr}
                                    >
                                        <td className={tableStyle.td}>
                                            {index + 1}
                                        </td>
                                        <td className={tableStyle.td}>
                                            {item.easyproId}
                                        </td>
                                        <td className={tableStyle.td}>
                                            {item.userName}
                                        </td>
                                        <td className={tableStyle.td}>
                                            {item.name}
                                        </td>
                                        {branch === "常滑" && (
                                            <td className={tableStyle.td}>
                                                {item.floor}
                                            </td>
                                        )}
                                        <td className={tableStyle.td}>
                                            <input
                                                type="checkbox"
                                                checked={item.checkedIn}
                                                className="h-4 w-4 accent-blue-800"
                                                onChange={() =>
                                                    handleCheckboxClick({
                                                        _id: item._id,
                                                        date: item.date,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="flex h-full w-2/6">
                <div className="mx-auto mt-40">
                    <CalendarComponent />
                </div>
            </div>
        </div>
    );
}

export default CheckedInStatus;
