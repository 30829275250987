import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth";
import crudReducer from "./crud";
import translatorReducer from "./TranslatorReducer/translatorSlice";

export const store = configureStore({
    reducer: {
        translator: translatorReducer,
        auth: authReducer,
        crud: crudReducer,
    },
});
