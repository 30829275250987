import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { update_food } from "../actions/crud";
import {
    ChiryuFoodNames,
    HakataFoodNames,
    HonshaFoodNames,
    NagoyaFoodNames,
    ShimaneFoodNames,
    TokonameFoodNames,
    TokyoFoodNames,
} from "../data/data";

const UpdateFoodModal = ({ showModal, setShowModal, message, data }) => {
    const dispatch = useDispatch();
    const [error, setError] = useState("");

    const inputClass =
        "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500";
    const buttonClass =
        "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 font-medium rounded-lg text-sm w-1/6 px-5 py-2.5 text-center";
    // If showModal is false, return null (modal is hidden)

    const [formData, setFormData] = useState({
        easyproId: 0,
        name: "",
        userName: "",
        date: "",
        floor: 1,
    });

    const [currentBranch, setCurrentBranch] = useState("常滑");
    const [FoodNames, setFoodNames] = useState(TokonameFoodNames);

    const setTimedMessage = (msg, time = 2000) => {
        setError(msg);
        setTimeout(() => {
            setError("");
        }, time);
    };

    const branchFoodNamesMapping = {
        本社: HonshaFoodNames,
        常滑: TokonameFoodNames,
        知立: ChiryuFoodNames,
        島根: ShimaneFoodNames,
        名古屋: NagoyaFoodNames,
        東京: TokyoFoodNames,
        博多: HakataFoodNames,
    };

    useEffect(() => {
        setFoodNames(branchFoodNamesMapping[currentBranch]);
        // Reset name when branch changes
        setFormData((prevFormData) => ({
            ...prevFormData,
            name: FoodNames[0],
        }));
    }, [currentBranch, FoodNames]);

    const handleBranchChange = (e) => {
        setCurrentBranch(e.target.value);
    };

    useEffect(() => {
        if (data) {
            setFormData({
                easyproId: data.easyproId,
                name: data.name,
                userName: data.userName,
                date: data.date,
                floor: 1,
            });
        }
    }, [data]);

    if (!showModal) return null;

    const branches = ["本社", "常滑", "知立", "島根", "名古屋", "東京", "博多"];

    // Inside UpdateFoodModal component
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "floor") {
            setFormData({
                ...formData,
                [name]: value,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = () => {
        if (!formData.name) {
            setFormData({ ...formData, name: FoodNames[0] });
        }
        if (!formData.name) {
            setTimedMessage("Select the Food name before submission.");
        } else {
            dispatch(update_food({ formData, monthOffset: formData.date }));
            setFormData({
                easyproId: 0,
                name: "",
                userName: "",
                date: "",
            });
            setShowModal(false);
        }
    };

    return (
        <div
            id="popup-modal"
            tabIndex="-1"
            className="fixed inset-0 flex h-auto w-auto items-center justify-center"
        >
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="relative w-1/3 p-4">
                <div className="relative flex flex-col rounded-lg bg-white shadow-lg">
                    <div className="mx-auto my-5 text-2xl">{message}</div>
                    <div>
                        <form className="mx-auto my-5 w-5/6">
                            <div className="mb-5">
                                <div>EasyproID</div>
                                <input
                                    className={`${inputClass} cursor-no-drop`}
                                    name="easyproId"
                                    value={formData.easyproId}
                                    onChange={handleInputChange}
                                    disabled
                                />
                            </div>
                            <div className="mb-5">
                                <div>User Name</div>
                                <input
                                    className={`${inputClass} cursor-no-drop`}
                                    name="userName"
                                    value={formData.userName}
                                    onChange={handleInputChange}
                                    disabled
                                />
                            </div>
                            <div className="mb-5">
                                <div>Date</div>
                                <input
                                    className={`${inputClass} cursor-no-drop`}
                                    name="date"
                                    value={formData.date}
                                    onChange={handleInputChange}
                                    disabled
                                />
                            </div>
                            <div className="mb-5">
                                <div>Branch</div>
                                <select
                                    className={inputClass}
                                    name="branch"
                                    value={currentBranch}
                                    onChange={handleBranchChange}
                                >
                                    {branches.map((branch) => (
                                        <option key={branch} value={branch}>
                                            {branch}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {currentBranch === "常滑" && (
                                <div className="mb-5">
                                    <div>Floor</div>
                                    <select
                                        className={inputClass}
                                        name="floor"
                                        value={formData.floor}
                                        onChange={handleInputChange}
                                    >
                                        <option value={1}>1F</option>
                                        <option value={2}>2F</option>
                                    </select>
                                </div>
                            )}

                            <div className="mb-5">
                                <div>Name</div>
                                <select
                                    className={inputClass}
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                >
                                    {FoodNames.map((foodName) => (
                                        <option key={foodName} value={foodName}>
                                            {foodName}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="flex justify-between">
                                <button
                                    className={buttonClass}
                                    type="button"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                                <button
                                    type="button" // Change type to button to prevent form submission
                                    onClick={() => setShowModal(false)}
                                    className={buttonClass}
                                >
                                    Cancel
                                </button>
                            </div>
                            {error && (
                                <div className="pt-2 text-center text-red-500">
                                    {error}
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateFoodModal;
