import { Button, Center, Checkbox, Loader, Table } from "@mantine/core";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { useDispatch, useSelector } from "react-redux";
import {
    fetch_delivery_staff_checkbox_action,
    trigger_today_food_count,
    update_delivery_checkbox_data,
} from "../../actions/crud";
import { useLanguage } from "../../hooks/useLanguage";

// Helper function to convert date to Japan Standard Time
const toJST = (date) => {
    return moment(date).tz("Asia/Tokyo");
};

const DeliveryStaffCheckbox = () => {
    const branches = ["常滑", "本社", "知立", "島根", "名古屋", "東京", "博多"];
    const floors = [1, 2];
    const [branch, setBranch] = useState(branches[0]);
    const [floor, setFloor] = useState(1);
    const dispatch = useDispatch();
    const language = useLanguage();

    let firstPosition, secondPosition, lastPosition;
    let first_total_string = "";
    let second_total_string = "";

    switch (branch) {
        case "常滑":
            firstPosition = 3;
            secondPosition = 12;
            lastPosition = 13;
            first_total_string = "たつえ";
            second_total_string = "とこなめ";
            break;
        case "本社":
            firstPosition = 3;
            secondPosition = 10;
            lastPosition = 11;
            first_total_string = "たつえ";
            second_total_string = "玉清";
            break;
        case "知立":
            firstPosition = 3;
            secondPosition = 10;
            lastPosition = 11;
            first_total_string = "たつえ";
            second_total_string = "玉清";
            break;
        case "島根":
            firstPosition = 7;
            secondPosition = 11;
            lastPosition = 12;
            first_total_string = "アゴ弁";
            second_total_string = "niconico";
            break;
        case "名古屋":
            firstPosition = 4;
            lastPosition = 5;
            secondPosition = 50;
            first_total_string = "ミノヤ大盛";
            second_total_string = "";
            break;
        case "東京":
            firstPosition = 1;
            lastPosition = 2;
            secondPosition = 50;
            first_total_string = "田中新川";
            second_total_string = "";
            break;
        case "博多":
            firstPosition = 1;
            lastPosition = 2;
            secondPosition = 50;
            first_total_string = "はたなか";
            second_total_string = "";
            break;
        default:
            firstPosition = 2;
            secondPosition = 3;
            lastPosition = 2;
            first_total_string = "";
            second_total_string = "";
    }

    const [selectedDate, setSelectedDate] = useState(
        toJST(new Date()).toDate(),
    );
    const [activeStartDate, setActiveStartDate] = useState(
        toJST(new Date()).toDate(),
    );

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(
                fetch_delivery_staff_checkbox_action({
                    branch,
                    floor,
                    selectedDate,
                }),
            );
        };
        fetchData();
    }, [branch, floor, selectedDate, dispatch]);

    const data = useSelector(
        (state) => state.crud.data.deliveryStaffCheckBoxData,
    );
    const loading = useSelector((state) => state.crud.isLoading);

    const handleBranchChange = (selectedBranch) => {
        setBranch(selectedBranch);
        setFloor(1); // Reset floor when switching branches
    };

    const handleFloorChange = (selectedFloor) => {
        setFloor(selectedFloor);
    };

    const tileClassName = ({ date, view }) => {
        if (view === "month") {
            const isToday = moment().isSame(date, "day");
            const isSelected = moment(selectedDate).isSame(date, "day");
            let classes = "h-12 border border-gray-400"; // Default class applied to all tiles
            if (isSelected) {
                classes += " bg-blue-300 text-white rounded-full"; // Highlight selected date
            } else if (isToday) {
                classes += " bg-color2 rounded-full"; // Shade today's date
            }
            return classes;
        }
        return "";
    };

    const handleClick = (date) => {
        setSelectedDate(toJST(date).toDate());
    };

    const handlePrevMonth = () => {
        setActiveStartDate(
            toJST(
                moment(activeStartDate)
                    .subtract(1, "month")
                    .startOf("month")
                    .toDate(),
            ).toDate(),
        );
    };

    const handleNextMonth = () => {
        setActiveStartDate(
            toJST(
                moment(activeStartDate)
                    .add(1, "month")
                    .startOf("month")
                    .toDate(),
            ).toDate(),
        );
    };

    const handleCheckbox = ({ childId, parentId, isChecked }) => {
        dispatch(
            update_delivery_checkbox_data({ childId, parentId, isChecked }),
        );
    };

    const handleTriggerTodayFoodCount = async () => {
        await dispatch(trigger_today_food_count());
        await dispatch(
            fetch_delivery_staff_checkbox_action({
                branch,
                floor,
                selectedDate,
            }),
        );
    };

    return (
        <div className="flex min-h-screen w-screen flex-col overflow-y-auto bg-gradient-to-r from-blue-200 to-cyan-200">
            <div className="flex w-full flex-row">
                <div className="mx-auto my-auto py-12 text-3xl">
                    日付 : {moment(selectedDate).format("YYYY-MM-DD")}
                </div>
            </div>
            <div className="flex min-h-screen w-full flex-row">
                <div className="flex w-2/12 flex-row justify-evenly">
                    <div>
                        <select
                            className="mx-auto h-10 appearance-none rounded-full border border-gray-300 bg-white px-5 text-center text-gray-600 hover:border-gray-400 focus:outline-none"
                            onChange={(event) =>
                                handleBranchChange(event.target.value)
                            }
                        >
                            {branches.map((b) => (
                                <option
                                    key={b}
                                    value={b}
                                    className="bg-gray-200"
                                >
                                    {b}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        {branch === "常滑" && (
                            <select
                                className="mx-auto h-10 appearance-none rounded-full border border-gray-300 bg-white px-5 text-center text-gray-600 hover:border-gray-400 focus:outline-none"
                                onChange={(event) =>
                                    handleFloorChange(event.target.value)
                                }
                            >
                                {floors.map((f) => (
                                    <option
                                        key={f}
                                        value={f}
                                        className={`bg-gray-200 ${
                                            floor === f
                                                ? "bg-red-500 text-white"
                                                : ""
                                        }`}
                                    >
                                        {f}階
                                    </option>
                                ))}
                            </select>
                        )}
                    </div>
                </div>
                <div className="mb-20 flex w-6/12 text-center">
                    {loading ? (
                        <div className="mx-auto mt-20">
                            <Loader color="blue" size="xl" type="dots" />
                        </div>
                    ) : !data || data.length === 0 ? (
                        <div className="flex h-full w-full flex-col space-y-20">
                            <div className="mx-auto pt-20 text-xl font-medium">
                                {
                                    language.delivery_staff_checkbox_page
                                        .no_data_available
                                }
                            </div>
                            {toJST(selectedDate).format("YYYY-MM-DD") ===
                                toJST(new Date()).format("YYYY-MM-DD") && (
                                <div>
                                    <Button
                                        variant="filled"
                                        color="rgba(115, 135, 101, 1)"
                                        size="md"
                                        radius="xl"
                                        onClick={handleTriggerTodayFoodCount}
                                    >
                                        <span className="text-xl font-medium">
                                            {
                                                language
                                                    .delivery_staff_checkbox_page
                                                    .generate_data
                                            }
                                        </span>
                                    </Button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Table
                            stickyHeader
                            highlightOnHover
                            withTableBorder
                            withColumnBorders
                            className="bg-white"
                        >
                            <Table.Tr style={{ backgroundColor: "#dfd3c3" }}>
                                <Table.Th style={{ textAlign: "center" }}>
                                    {language.delivery_staff_checkbox_page.name}
                                </Table.Th>
                                <Table.Th style={{ textAlign: "center" }}>
                                    {
                                        language.delivery_staff_checkbox_page
                                            .count
                                    }
                                </Table.Th>
                                <Table.Th style={{ textAlign: "center" }}>
                                    {
                                        language.delivery_staff_checkbox_page
                                            .checkin
                                    }
                                </Table.Th>
                            </Table.Tr>
                            {data[0].data.map((d, index) => {
                                const { name, count, isChecked, _id } = d;

                                return (
                                    <Table.Tr
                                        key={index}
                                        style={{
                                            backgroundColor:
                                                index === firstPosition
                                                    ? "#e7e6e1" // Hex color for firstPosition e7e6e1
                                                    : index === secondPosition
                                                      ? "#e1f6f4" // Hex color for secondPosition e1f6f4
                                                      : index === lastPosition
                                                        ? "#abcecf" // Hex color for secondPosition
                                                        : "transparent", // Default background
                                        }}
                                    >
                                        <Table.Td>
                                            {name === "totalSum"
                                                ? "合計"
                                                : name === "たつえ合計"
                                                  ? first_total_string + "合計"
                                                  : name === "常滑合計"
                                                    ? second_total_string +
                                                      "合計"
                                                    : name}
                                        </Table.Td>
                                        <Table.Td>{count}</Table.Td>
                                        <Table.Td>
                                            <Center>
                                                <Checkbox
                                                    checked={isChecked}
                                                    size="md"
                                                    disabled={
                                                        toJST(
                                                            selectedDate,
                                                        ).format(
                                                            "YYYY-MM-DD",
                                                        ) !==
                                                        toJST(
                                                            new Date(),
                                                        ).format("YYYY-MM-DD")
                                                    }
                                                    onChange={() =>
                                                        handleCheckbox({
                                                            childId: _id,
                                                            parentId:
                                                                data[0]._id,
                                                            isChecked:
                                                                !isChecked,
                                                        })
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </Center>
                                        </Table.Td>
                                    </Table.Tr>
                                );
                            })}
                        </Table>
                    )}
                </div>
                <div className="w-5/12">
                    <div className="mx-4">
                        <div className="mb-2 flex items-center justify-between">
                            <Button
                                variant="filled"
                                color="rgba(115, 135, 101, 1)"
                                onClick={handlePrevMonth}
                            >
                                {language.delivery_staff_checkbox_page.prev}
                            </Button>
                            <span>
                                {moment(activeStartDate).format("MMMM YYYY")}
                            </span>

                            <Button
                                variant="filled"
                                color="rgba(115, 135, 101, 1)"
                                onClick={handleNextMonth}
                            >
                                {language.delivery_staff_checkbox_page.next}
                            </Button>
                        </div>
                        <Calendar
                            className="border border-gray-600 bg-[#e0dfdfec] text-center"
                            locale="ja-JP"
                            defaultView="month"
                            showNeighboringMonth={false}
                            tileClassName={tileClassName}
                            activeStartDate={activeStartDate}
                            onActiveStartDateChange={({ activeStartDate }) => {
                                if (activeStartDate) {
                                    setActiveStartDate(
                                        toJST(activeStartDate).toDate(),
                                    );
                                }
                            }}
                            minDetail="decade"
                            navigationAriaLabel="Go up"
                            onClickDay={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeliveryStaffCheckbox;
