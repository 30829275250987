import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import { store } from "./redux/store";
// core styles are required for all packages
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";

ReactDOM.createRoot(document.getElementById("root")).render(
    <Provider store={store}>
        <MantineProvider>
            <App />
        </MantineProvider>
    </Provider>,
);
