const TokonameFoodNames = [
    // "たつえ○(常滑)",
    // "たつえ◎(常滑)",
    // "たつえ△(常滑)",
    "常滑上〇(常滑)",
    "常滑上◎(常滑)",
    "常滑上△(常滑)",
    "すこやか〇(常滑)",
    "すこやか◎(常滑)",
    "すこやか△(常滑)",
    // "こころ〇(常滑)",
    "Cランチ○(常滑)",
    // "Cランチ◎(常滑)",
    "Cランチ△(常滑)",
    "丼セット(常滑)",
    // "麺(常滑)",
];

const HonshaFoodNames = [
    // "たつえ○(本社)",
    // "たつえ◎(本社)",
    // "たつえ△(本社)",
    "こころ〇(本社)",
    "こころ◎(本社)",
    "こころ△(本社)",
    "なでしこ(本社)",
    "Cランチ○(本社)",
    "Cランチ◎(本社)",
    "Cランチ△(本社)",
    "丼セット(本社)",
    // "麺＆ご飯(本社)",
    // "麺(本社)",
];

const ChiryuFoodNames = [
    "たつえ○(知立)",
    "たつえ◎(知立)",
    "たつえ△(知立)",
    "こころ〇(知立)",
    "こころ◎(知立)",
    "こころ△(知立)",
    "なでしこ(知立)",
    // "麺＆ご飯(知立)",
    // "麺(知立)",
];

const ShimaneFoodNames = [
    "健康弁当(島根)",
    "健康小盛(島根)",
    "健康おかず(島根)",
    "洋風ランチ(島根)",
    "洋風小盛(島根)",
    "洋風おかず(島根)",
    "nicoヘルシー(島根)",
    "nicoデラックス(島根)",
    "nicoデラックス大(島根)",
    // "麺(島根)",
];

const NagoyaFoodNames = [
    "ミノヤ普通(名古屋)",
    "ミノヤ大盛(名古屋)",
    "ミノヤおかず(名古屋)",
    // "麺(名古屋)",
];

const AllFoodNames = [
    "たつえ○(常滑)",
    "たつえ◎(常滑)",
    "たつえ△(常滑)",
    "常滑上〇(常滑)",
    "常滑上◎(常滑)",
    "常滑上△(常滑)",
    "すこやか〇(常滑)",
    "すこやか◎(常滑)",
    "すこやか△(常滑)",
    "たつえ○(本社)",
    "たつえ◎(本社)",
    "たつえ△(本社)",
    "こころ〇(本社)",
    "こころ◎(本社)",
    "こころ△(本社)",
    "なでしこ(本社)",
    "たつえ○(知立)",
    "たつえ◎(知立)",
    "たつえ△(知立)",
    "こころ〇(知立)",
    "こころ◎(知立)",
    "こころ△(知立)",
    "なでしこ(知立)",
    "健康弁当(島根)",
    "健康小盛(島根)",
    "健康おかず(島根)",
    "洋風ランチ(島根)",
    "洋風小盛(島根)",
    "洋風おかず(島根)",
    "ミノヤ普通(名古屋)",
    "ミノヤ大盛(名古屋)",
    "ミノヤおかず(名古屋)",
    "田中新川(東京)",
];

const TokyoFoodNames = ["田中新川(東京)"];

const HakataFoodNames = ["はたなか日替わり(博多)"];

export {
    TokonameFoodNames,
    HonshaFoodNames,
    ChiryuFoodNames,
    ShimaneFoodNames,
    NagoyaFoodNames,
    TokyoFoodNames,
    AllFoodNames,
    HakataFoodNames,
};
