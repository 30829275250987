import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import CalendarMonthSharpIcon from "@mui/icons-material/CalendarMonthSharp";
import FactCheckSharpIcon from "@mui/icons-material/FactCheckSharp";
import RamenDiningIcon from "@mui/icons-material/RamenDining";
import RuleSharpIcon from "@mui/icons-material/RuleSharp";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { useDispatch } from "react-redux";
import { useLanguage } from "../hooks/useLanguage";

import LanguageSwitcher from "./LanguageSwitcher/LanguageSwitcher";

import {
    AccountCircle as AccountCircleIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    Grading,
    Menu as MenuIcon,
    QueryStats as QueryStatsIcon,
} from "@mui/icons-material";
import {
    AppBar,
    Box,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    Toolbar,
    Typography,
    useTheme,
} from "@mui/material";
import { LOGOUT } from "../constants/actionTypes";

const drawerWidth = 240;

const StyledAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
}));

const AdminNavbar = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [currentButton, setCurrentButton] = useState(null); // State to track the currently selected button

    const language = useLanguage();

    const {
        administrator,
        monthly_summary,
        food_history,
        employee,
        dept_wise,
        daily_counts,
        checkin_status,
        logout,
        permission,
        delivery_person_checkbox,
    } = language.admin_navbar;

    const navLinks = [
        {
            to: "admin/monthly_summary",
            text: monthly_summary,
            icon: <QueryStatsIcon />,
        },
        {
            to: "admin/food_history",
            text: food_history,
            icon: <RamenDiningIcon />,
        },
        {
            to: "admin/employee",
            text: employee,
            icon: <AccountCircleIcon />,
        },
        {
            to: "admin/deptwise",
            text: dept_wise,
            icon: <SummarizeIcon />,
        },
        {
            to: "admin/dailycount",
            text: daily_counts,
            icon: <CalendarMonthSharpIcon />,
        },
        {
            to: "admin/checkedin",
            text: checkin_status,
            icon: <FactCheckSharpIcon />,
        },
        {
            to: "admin/permission",
            text: permission,
            icon: <RuleSharpIcon />,
        },
        {
            to: "admin/delivery-staff",
            text: delivery_person_checkbox,
            icon: <Grading />,
        },
    ];

    const navigate = useNavigate();

    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    const handleLogout = () => {
        dispatch({ type: LOGOUT });
        navigate("/");
    };

    const handleButtonClick = (index) => {
        setCurrentButton(index);
    };

    return (
        <>
            <Box
                sx={{
                    // display: "flex",
                    position: "relative",
                    mb: "65px",
                }}
            >
                <CssBaseline />
                <StyledAppBar
                    position="fixed"
                    open={open}
                    sx={{ backgroundColor: "paleturquoise", color: "black" }}
                >
                    <Toolbar>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{ flexGrow: 1 }}
                            component="div"
                        >
                            <button
                                onClick={() => {
                                    setCurrentButton(null);
                                    navigate("/admin");
                                }}
                            >
                                {administrator}
                            </button>
                        </Typography>
                        <LanguageSwitcher />
                        <IconButton
                            color="LightGray"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerOpen}
                            sx={{ ...(open && { display: "none" }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </StyledAppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        "& .MuiDrawer-paper": { width: drawerWidth },
                    }}
                    variant="persistent"
                    anchor="right"
                    open={open}
                >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === "rtl" ? (
                                <ChevronLeftIcon />
                            ) : (
                                <ChevronRightIcon />
                            )}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List sx={{ backgroundColor: "#cfd8dc" }}>
                        {navLinks.map(({ to, text, icon }, index) => (
                            <Link
                                to={to}
                                key={to}
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                }}
                            >
                                <ListItem>
                                    <ListItemButton
                                        onClick={() => handleButtonClick(index)}
                                        // selected={currentButton === index} // Apply selected style if the current button is selected
                                        sx={{
                                            backgroundColor:
                                                currentButton === index
                                                    ? "#e8eaf6"
                                                    : "transparent",
                                            // Add more custom styles as needed
                                        }}
                                    >
                                        <ListItemIcon>{icon}</ListItemIcon>
                                        <ListItemText primary={text} />
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        ))}
                        <button
                            className="w-full rounded-full bg-stone-500 px-4 py-2 font-bold text-white hover:bg-slate-700"
                            onClick={handleLogout}
                        >
                            {logout}
                        </button>
                    </List>
                    <Divider />
                </Drawer>
            </Box>
        </>
    );
};

export default AdminNavbar;
