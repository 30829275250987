// translatorReducer.js
import cn from "../../Languages/translation/cn_cn.js";
import en from "../../Languages/translation/eng_eng"; // import English language file
import jp from "../../Languages/translation/jp_jp"; // import Japanese language file
import vi from "../../Languages/translation/vi_vi";
import { SWITCH_LANGUAGE } from "./action.js";

const initialState = {
    language: jp, // default language is Japanese
};

const translatorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SWITCH_LANGUAGE:
            let newLanguage;
            switch (action.payload) {
                case "en":
                    newLanguage = en;
                    break;
                case "jp":
                    newLanguage = jp;
                    break;
                case "vi":
                    newLanguage = vi;
                    break;
                case "cn":
                    newLanguage = cn;
                    break;
                default:
                    newLanguage = jp; // default back to Japanese if undefined or unknown language code
            }
            return {
                ...state,
                language: newLanguage,
            };
        default:
            return state;
    }
};

export default translatorReducer;
