const lang = {
    add_user: "ユーザーを追加",
    administrator: "管理者",
    all: "全て",
    branch: {
        all: "全て",
        chiryu: "知立",
        honsha: "本社",
        shimane: "島根",
        tokoname: "常滑",
    },
    modal: {
        submission_successful: "データは正常に送信されました。",
        registration_successful:
            "登録が成功しました。ログインページに移動します。",
    },
    months: {
        previous_month: "先月",
        this_month: "今月",
        next_month: "来月",
        next_next_month: "再来月",
        current_month: "当月",
        next_next_next_month: "再来月の次",
    },
    admin_navbar: {
        administrator: "管理者",
        monthly_summary: "月次集計",
        food_history: "注文履歴",
        employee: "従業員情報",
        dept_wise: "部署のデータ",
        daily_counts: "日次データ数",
        checkin_status: "チェックリスト",
        permission: "カレンダー登録",
        logout: "ログアウト",
        generatefood: "食料を生成",
        delivery_person_checkbox: "当日発注チェックリスト",
    },
    login_register_page: {
        login_header: "アカウントにログインしてください",
        register_header: "アカウントを登録してください",
        your_email: "あなたのEasyPro ID",
        easyproid: "EasyproId",
        password: "パスワード",
        new_user: "新しいユーザー？？",
        already_have_an_account: "すでにアカウントをお持ちですか？？",
        signup: "登録",
        signin: "ログイン",
    },
    food_selection_page: {
        deadline: "当日の変更は8：45まで！！！",
        link_text: " 夏季限定麺メニュー実施中♪ 注文可能日はこちらを",
        food_selection_page: "食品選択ページ",
    },

    cancel: "キャンセル",

    do_you_already_have_an_account: "すでにアカウントをお持ちですか ??",
    easyproid: "EasyproId",
    employee_information: "従業員情報",
    employee_profile: {
        easyproid: "EasyproId",
        employee_profile: "従業員プロフィール",
        employment_status: "雇用形態",
        group: "グループ",
        name: "氏名",
        team: "チーム",
        work_location: "勤務地",
    },
    employee_information_page: {
        employee_information: "従業員情報",
        add_user: "ユーザーを追加",
        remove_user: "ユーザーを削除",
        update_user: "ユーザーを更新",
    },

    page_titles: {
        login_page: "ログイン - お弁当注文",
        register_page: "登録 - お弁当注文",
        food_selection_page: "食品選択 - お弁当注文",
        profile_page: "プロフィール - お弁当注文",
        homepage: "お弁当注文",
    },
    food_generation_page: {
        food_generation_page: "食糧生成ページ",
        selected_month: "選択した月",
        generate_food: "結果を生成する",
        data_submission_thank: "データが生成されました。ありがとうございます。",
    },
    everyone: "みんな",
    export_to_excel: "Excelにエクスポート",
    first_time: "初めてですか??",
    food_history: "食の歴史",
    homepage: "トップページ",
    login: "ログイン",
    login_page: "ログインページ",
    login_header: "アカウントにログインします",
    select_order_location: "注文拠点を選択",
    logout: "ログアウト",
    monthly_summary: "月次集計",
    name: "氏名",
    next: "次へ",
    next_month: "来月",
    order_history: "注文履歴",
    orders: "注文",
    previous_month: "前月",
    profile: "プロフィール",
    register: "登録",
    remove_food: "取り除く",
    remove_user: "ユーザーを削除",
    shimane: "島根",
    submit: "提出",
    team: "チーム",
    this_month: "今月",
    tokoname: "常滑",
    update: "更新",
    update_location: "場所を更新",
    user_registration: "ユーザー登録",
    work_location: "勤務地",

    day: {
        1: "日",
        2: "月",
        3: "火",
        4: "水",
        5: "木",
        6: "金",
        7: "土",
    },

    password: "パスワード",
    this_month_data: "先月のデータ",
    last_month_data: "先月のデータ",
    floor: "階",
    errors: {
        user_doesnot_exist: "ユーザーが存在しません。",
        invalid_credentials: "無効な資格情報",
        user_not_valid: "ユーザーが無効です。",
        user_already_exists: "ユーザーは既に存在します。",
        error_while_creating_user: "ユーザーの作成中に問題が発生しました",
        all_fields_required: "すべてのフィールドに入力する必要があります",
        id_mustbe_number: "ユーザーIDは数字である必要があります",
        no_token: "トークンが提供されていません。",
        invalid_token: "無効なトークン",
        failed_to_authenticate_token: "トークンの認証に失敗しました",
        authorization_fail: "ユーザーの認証に失敗しました",
    },
    delivery_staff_checkbox_page: {
        name: "名前",
        count: "数",
        checkin: "チェックイン",
        next: "次",
        prev: "前",
        no_data_available: "データがありません",
        generate_data: "データを生成する",
    },
};

export default lang;
