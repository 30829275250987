const lang = {
    add_user: "添加用户",
    administrator: "管理员",
    all: "全部",
    branch: {
        all: "全部",
        chiryu: "知立",
        honsha: "总公司",
        shimane: "岛根",
        tokoname: "常滑",
    },
    modal: {
        submission_successful: "您的数据已成功提交。",
        registration_successful: "注册成功。前往登录页面",
    },
    months: {
        previous_month: "上个月",
        this_month: "本月",
        next_month: "下个月",
        next_next_month: "下下个月",
        current_month: "当前月份",
        next_next_next_month: "下下下个月",
    },
    admin_navbar: {
        administrator: "管理员",
        monthly_summary: "月度总结",
        food_history: "饮食历史",
        employee: "员工信息",
        dept_wise: "按部门",
        daily_counts: "每日统计",
        checkin_status: "签到状态",
        permission: "权限",
        logout: "登出",
        generatefood: "生成食物",
        delivery_person_checkbox: "当日订单清单",
    },
    login_register_page: {
        login_header: "请登录您的账户",
        register_header: "注册您的账户",
        your_email: "您的EasyproId",
        password: "密码",
        easyproid: "EasyproId",
        new_user: "新用户？",
        already_have_an_account: "已有账户？",
        signup: "注册",
        signin: "登录",
    },
    food_selection_page: {
        deadline: "今天的更改必须在8:45之前完成！！！",
        link_text: "夏季限定面食菜单♪点击这里订购日期",
        food_selection_page: "食物选择页面",
    },
    cancel: "取消",
    do_you_already_have_an_account: "已有账户？",
    easyproid: "Easypro ID",
    employee_information: "员工信息",
    employee_profile: {
        easyproid: "Easypro ID",
        employee_profile: "员工档案",
        employment_status: "员工状态",
        group: "群组",
        name: "名称",
        team: "团队",
        work_location: "工作地点",
    },
    employee_information_page: {
        employee_information: "员工信息",
        add_user: "添加用户",
        remove_user: "移除用户",
        update_user: "更新用户",
    },
    page_titles: {
        login_page: "登录 - お弁当注文",
        register_page: "注册 - お弁当注文",
        food_selection_page: "食物选择 - お弁当注文",
        profile_page: "档案 - お弁当注文",
        homepage: "首页 - お弁当注文",
    },
    food_generation_page: {
        food_generation_page: "数据生成页面",
        selected_month: "选定的月份",
        generate_food: "生成数据",
        data_submission_thank: "数据已提交。感谢您。",
    },
    everyone: "每个人",
    export_to_excel: "导出到Excel",
    first_time: "新用户？",
    food_history: "饮食历史",
    homepage: "首页",
    select_order_location: "选择订单位置",
    login: "登录",
    login_page: "登录页面",
    login_header: "登录您的账户",
    logout: "登出",
    monthly_summary: "月度总结",
    name: "名称",
    next: "下一页",
    next_month: "下个月",
    order_history: "订单历史",
    orders: "订单",
    previous_month: "上个月",
    profile: "员工档案",
    register: "注册",
    remove_food: "移除食物",
    remove_user: "移除用户",
    shimane: "岛根",
    submit: "提交",
    team: "团队",
    this_month: "本月",
    tokoname: "常滑",
    update: "更新",
    update_location: "更新位置",
    user_registration: "用户注册",
    work_location: "工作地点",

    day: {
        1: "日",
        2: "一",
        3: "二",
        4: "三",
        5: "四",
        6: "五",
        7: "六",
    },
    password: "密码",
    this_month_data: "本月数据",
    last_month_data: "上月数据",
    floor: "楼",
    errors: {
        user_doesnot_exist: "用户不存在。",
        invalid_credentials: "无效的凭据",
        user_not_valid: "用户无效。",
        user_already_exists: "用户已存在。",
        error_while_creating_user: "创建用户时出错",
        all_fields_required: "必须填写所有字段",
        id_mustbe_number: "用户ID必须是数字",
        no_token: "未提供令牌。",
        invalid_token: "无效的令牌",
        failed_to_authenticate_token: "验证令牌失败",
        authorization_fail: "认证用户失败",
    },
    delivery_staff_checkbox_page: {
        name: "名称",
        count: "数",
        checkin: "签到",
        next: "下一页",
        prev: "上一页",
        no_data_available: "没有数据",
        generate_data: "生成数据",
    },
};

export default lang;
