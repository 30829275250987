import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { initAuth } from "./actions/auth";
import { router } from "./routing/main";

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(initAuth());
    }, [dispatch]);

    return <RouterProvider router={router} />;
}

export default App;
