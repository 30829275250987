import { useNavigate } from "react-router-dom";

const Modal = ({ showModal, setShowModal, message, toNavigate = false }) => {
    const navigate = useNavigate();
    // Function to handle modal close
    const closeModal = () => {
        setShowModal(false);
        if (toNavigate === true) {
            navigate("/");
        }
    };

    // If showModal is false, return null (modal is hidden)
    if (!showModal) return null;

    return (
        <div
            id="popup-modal"
            tabIndex="-1"
            className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden"
        >
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="relative w-full max-w-md p-4">
                <div className="relative rounded-lg bg-white shadow-lg dark:bg-gray-700">
                    <div className="p-4 text-center md:p-5">
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            {message}
                        </h3>
                        <button
                            type="button"
                            className="me-2 inline-flex items-center rounded-lg bg-green-400 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800"
                            onClick={closeModal}
                        >
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
